/* Import Custom Variables (Only in Sass, not in CSS)*/
@import url(https://fonts.googleapis.com/css?family=Raleway:700,500,600|Open+Sans:400,600,700);
@media print {
  .search__result_wrapper {
    display: none;
  }
}
.search-location-checkbox {
  padding-top: 0.2375rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.multi-select-dropdown {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  position: relative;
}
.multi-select-dropdown .glyphicon-ok {
  width: 8%;
  float: right;
  font-size: 1rem;
}

.air__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.air__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__search-form-container_main:before, .air__search-form-container_main:after {
  content: " ";
  display: table;
}
.air__search-form-container_main:after {
  clear: both;
}
.air__search-form-container_main .content {
  padding-left: 0;
}
.air__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.air__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-wrapper:before, .air__search-wrapper:after {
  content: " ";
  display: table;
}
.air__search-wrapper:after {
  clear: both;
}
.air__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.air__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.air__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.air__search-trip-type, .air__search-location, .air__search-date, .air__search-traveller, .air__search-multicity, .air__search-nationality, .air__search-residence-country, .air__search-currency, .air__additioal-option, .air__pnr-search-location, .air__product-selection, .air__advance-search, .air__advance-search-params, .air__search-supplier, .air__search-modify-journey {
  margin-bottom: 1rem;
}
.air__with-baggage_label {
  padding: 0.3125rem;
}
.air__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.air__pnr-fields:before, .air__pnr-fields:after {
  content: " ";
  display: table;
}
.air__pnr-fields:after {
  clear: both;
}
.air__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.air__search-trip-type, .air__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.air__search-trip-type:before, .air__search-trip-type:after, .air__search-modify-journey:before, .air__search-modify-journey:after {
  content: " ";
  display: table;
}
.air__search-trip-type:after, .air__search-modify-journey:after {
  clear: both;
}
.air__search-trip-type .radio-inline, .air__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .air__search-trip-type .radio-inline, .air__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.air__search-trip-type .radio-inline input[disabled], .air__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.air__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.air__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.air__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-location:before, .air__search-location:after {
  content: " ";
  display: table;
}
.air__search-location:after {
  clear: both;
}
.air__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search-date, .air__hub-surcharge, .air__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-date:before, .air__search-date:after, .air__hub-surcharge:before, .air__hub-surcharge:after, .air__search-date_hotel-dates:before, .air__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.air__search-date:after, .air__hub-surcharge:after, .air__search-date_hotel-dates:after {
  clear: both;
}
.air__search-date .search-date_select, .air__search-date_baggage-one-way, .air__hub-surcharge .search-date_select, .air__hub-surcharge_baggage-one-way, .air__search-date_hotel-dates .search-date_select, .air__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search-date_baggage-one-way .baggage-label, .air__hub-surcharge_baggage-one-way .baggage-label, .air__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.air__search-date_baggage-round-multicity .baggage-label, .air__hub-surcharge_baggage-round-multicity .baggage-label, .air__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.air__search-date_hotel-dates .search-date_select, .air__hub-surcharge_hotel-dates .search-date_select, .air__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.air__search-date .date-field-with-duration, .air__hub-surcharge .date-field-with-duration, .air__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.air__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.air__search-date-verticle:before, .air__search-date-verticle:after {
  content: " ";
  display: table;
}
.air__search-date-verticle:after {
  clear: both;
}
.air__search-date-verticle .date-range-picker__from-date, .air__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.air__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.air__hub-surcharge-container:before, .air__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.air__hub-surcharge-container:after {
  clear: both;
}
.air__search-nationality, .air__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-nationality:before, .air__search-nationality:after, .air__search-residence-country:before, .air__search-residence-country:after {
  content: " ";
  display: table;
}
.air__search-nationality:after, .air__search-residence-country:after {
  clear: both;
}
.air__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-currency:before, .air__search-currency:after {
  content: " ";
  display: table;
}
.air__search-currency:after {
  clear: both;
}
.air__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.air__search-multicity-row, .air__search-multicity_hotel-dates, .air__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-multicity-row:before, .air__search-multicity-row:after, .air__search-multicity_hotel-dates:before, .air__search-multicity_hotel-dates:after, .air__search-multicity_hotel-search-date:before, .air__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.air__search-multicity-row:after, .air__search-multicity_hotel-dates:after, .air__search-multicity_hotel-search-date:after {
  clear: both;
}
.air__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.air__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.air__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.air__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.air__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.air__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.air__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.air__search-multicity_date .search-date_select {
  width: 100%;
}
.air__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.air__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.air__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.air__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.air__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.air__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.air__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-traveller:before, .air__search-traveller:after {
  content: " ";
  display: table;
}
.air__search-traveller:after {
  clear: both;
}
.air__search-traveller:before, .air__search-traveller:after {
  content: " ";
  display: table;
}
.air__search-traveller:after {
  clear: both;
}
.air__search-traveller_adult, .air__search-traveller_child, .air__search-traveller_infant, .air__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.air__search-traveller_adult input, .air__search-traveller_child input, .air__search-traveller_infant input, .air__search-traveller_class input {
  text-align: center;
}
.air__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.air__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.air__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.air__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.air__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.air__advance-search-params:before, .air__advance-search-params:after {
  content: " ";
  display: table;
}
.air__advance-search-params:after {
  clear: both;
}
.air__advance-search-params_stops, .air__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.air__search-supplier:before, .air__search-supplier:after {
  content: " ";
  display: table;
}
.air__search-supplier:after {
  clear: both;
}
.air__supplier-selection label {
  padding-right: 2rem;
}
.air__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.air__additioal-option:before, .air__additioal-option:after {
  content: " ";
  display: table;
}
.air__additioal-option:after {
  clear: both;
}
.air__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.air__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search-submit:before, .air__search-submit:after {
  content: " ";
  display: table;
}
.air__search-submit:after {
  clear: both;
}
.air__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.air__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.air__search-submit_button .submit_button:focus, .air__search-submit_button .submit_button.focus, .air__search-submit_button .submit_button:active:focus, .air__search-submit_button .submit_button:active.focus, .air__search-submit_button .submit_button.active:focus, .air__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.air__search-submit_button .submit_button:hover, .air__search-submit_button .submit_button:focus, .air__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.air__search-submit_button .submit_button:active, .air__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.air__search-submit_button .submit_button.disabled, .air__search-submit_button .submit_button[disabled], fieldset[disabled] .air__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.air__search-form_container input[type=text], .air__search-form_container input[type=number],
.air__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.air__search-form_container input[type=text], .air__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.air__search-form_container .number-stepper {
  max-width: 250px;
}
.air__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.air__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.air__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.air__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.air__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.air__advertise p {
  margin-bottom: 0;
}
.air__search-time, .air__search-date, .air__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.air__search-time:before, .air__search-time:after, .air__search-date:before, .air__search-date:after, .air__input-field:before, .air__input-field:after {
  content: " ";
  display: table;
}
.air__search-time:after, .air__search-date:after, .air__input-field:after {
  clear: both;
}
.air__search-time_select, .air__search-date_select, .air__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.air__search-time_half, .air__search-date_half, .air__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search-nav {
  background-color: #087dc2;
}
.air__search-nav:before, .air__search-nav:after {
  content: " ";
  display: table;
}
.air__search-nav:after {
  clear: both;
}
.air__search-nav_list li {
  float: left;
  background-color: #087dc2;
  border-right: 2px solid;
  border-color: #fff;
}
.air__search-nav_list li a {
  padding: 0.625rem 0.625rem;
  display: block;
  color: #fff;
  text-transform: uppercase;
}
.air__search-nav_list li a:hover {
  background-color: #ec2227;
  color: #fff;
  text-decoration: none;
}
.air__search-nav_list .active {
  background-color: #ec2227;
}
.air__search-nav_list .active a {
  color: #fff;
}
@media (max-width: 991px) {
  .air__search-nav_list {
    display: none;
  }
}

.air__search_results .search-info {
  background-color: #087dc2;
}
.air__search_results .search-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__search_results .search-info_main:before, .air__search_results .search-info_main:after {
  content: " ";
  display: table;
}
.air__search_results .search-info_main:after {
  clear: both;
}
.air__search_results .search-info_main .content {
  padding-left: 0;
}
.air__search_results .search-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__search_results .search-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__search_results .search-info_wrapper {
    max-width: 1400px;
  }
}
.air__search_results .search-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__search_results .search-info_wrapper:before, .air__search_results .search-info_wrapper:after {
  content: " ";
  display: table;
}
.air__search_results .search-info_wrapper:after {
  clear: both;
}
.air__search_results .search-info__data {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  float: left;
  color: #f0f5f8;
  font-size: 1rem;
  display: block;
}
.air__search_results .search-info__modify {
  padding-top: 1px;
  padding-bottom: 1px;
  float: right;
}
.air__search_results .search-info__modify_button {
  padding: 0.3125rem 0.9375rem;
  font-size: 1rem;
  line-height: inherit;
  border-radius: 0;
  color: #000;
  background-color: #c7e5f6;
}
.air__search_results .search-info__modify_button:focus, .air__search_results .search-info__modify_button.focus, .air__search_results .search-info__modify_button:active:focus, .air__search_results .search-info__modify_button:active.focus, .air__search_results .search-info__modify_button.active:focus, .air__search_results .search-info__modify_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.air__search_results .search-info__modify_button:hover, .air__search_results .search-info__modify_button:focus, .air__search_results .search-info__modify_button.focus {
  color: #000;
  text-decoration: none;
  background-color: #9bd1ef;
}
.air__search_results .search-info__modify_button:active, .air__search_results .search-info__modify_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.air__search_results .search-info__modify_button.disabled, .air__search_results .search-info__modify_button[disabled], fieldset[disabled] .air__search_results .search-info__modify_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.air__search_results .search-details {
  background-color: #fff;
}
.air__search_results .search-details_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__search_results .search-details_main:before, .air__search_results .search-details_main:after {
  content: " ";
  display: table;
}
.air__search_results .search-details_main:after {
  clear: both;
}
.air__search_results .search-details_main .content {
  padding-left: 0;
}
.air__search_results .search-details_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__search_results .search-details_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__search_results .search-details_wrapper {
    max-width: 1400px;
  }
}
.air__search_results .search-details_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__search_results .search-details_wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
}
.air__search_results .search-details__row:before, .air__search_results .search-details__row:after {
  content: " ";
  display: table;
}
.air__search_results .search-details__row:after {
  clear: both;
}
.air__search_results .search-details__row > li, .air__search_results .search-details__row .search-details__return {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  padding-left: 0;
  padding-right: 0;
  border-right: 2px solid #c7e5f6;
}
.air__search_results .search-details__row > li:first-child, .air__search_results .search-details__row .search-details__return:first-child {
  border-left: 2px solid #c7e5f6;
}
.air__search_results .search-details__row > li:nth-child(3), .air__search_results .search-details__row .search-details__return:nth-child(3) {
  border-right: none;
}
.air__search_results .search-details__row > li:last-child, .air__search_results .search-details__row .search-details__return:last-child {
  padding-right: 0.9375rem;
  border-right: none;
}
.air__search_results .search-details__row .search-details__multicity {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.air__search_results .search-details__row .search-details_duration {
  width: 35%;
  border-right: none;
}
.air__search_results .search-details__row_traveller {
  margin-right: -15px;
}
.air__search_results .search-details .adult__data,
.air__search_results .search-details .child__data,
.air__search_results .search-details .infants__data {
  display: block;
}
.air__search_results .search-details .adult__data_heading,
.air__search_results .search-details .child__data_heading,
.air__search_results .search-details .infants__data_heading {
  color: #087dc2;
}
.air__search_results .search-details .adult__data_value,
.air__search_results .search-details .child__data_value,
.air__search_results .search-details .infants__data_value {
  color: #838383;
  font-family: "Open Sans";
}
.air__search_results .search-details .oneway__data,
.air__search_results .search-details .return__data,
.air__search_results .search-details .duration__data {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.air__search_results .search-details .oneway__data:before, .air__search_results .search-details .oneway__data:after,
.air__search_results .search-details .return__data:before,
.air__search_results .search-details .return__data:after,
.air__search_results .search-details .duration__data:before,
.air__search_results .search-details .duration__data:after {
  content: " ";
  display: table;
}
.air__search_results .search-details .oneway__data:after,
.air__search_results .search-details .return__data:after,
.air__search_results .search-details .duration__data:after {
  clear: both;
}
.air__search_results .search-details .oneway__data_icon,
.air__search_results .search-details .return__data_icon,
.air__search_results .search-details .duration__data_icon {
  float: left;
  font-size: 2.25rem;
}
.air__search_results .search-details .oneway__data_detail,
.air__search_results .search-details .return__data_detail,
.air__search_results .search-details .duration__data_detail {
  float: left;
  padding: 9px;
  padding-right: 0;
  width: 80%;
}
.air__search_results .search-details .oneway__data_detail .location,
.air__search_results .search-details .return__data_detail .location,
.air__search_results .search-details .duration__data_detail .location {
  color: #087dc2;
}
.air__search_results .search-details .oneway__data_detail .departure:before, .air__search_results .search-details .oneway__data_detail .departure:after,
.air__search_results .search-details .return__data_detail .departure:before,
.air__search_results .search-details .return__data_detail .departure:after,
.air__search_results .search-details .duration__data_detail .departure:before,
.air__search_results .search-details .duration__data_detail .departure:after {
  content: " ";
  display: table;
}
.air__search_results .search-details .oneway__data_detail .departure:after,
.air__search_results .search-details .return__data_detail .departure:after,
.air__search_results .search-details .duration__data_detail .departure:after {
  clear: both;
}
.air__search_results .search-details .oneway__data_detail .departure_date,
.air__search_results .search-details .return__data_detail .departure_date,
.air__search_results .search-details .duration__data_detail .departure_date {
  float: left;
}
.air__search_results .search-details .oneway__data_detail .departure__detail,
.air__search_results .search-details .return__data_detail .departure__detail,
.air__search_results .search-details .duration__data_detail .departure__detail {
  float: left;
}
.air__search_results .search-details .oneway__data_detail .departure__detail:before, .air__search_results .search-details .oneway__data_detail .departure__detail:after,
.air__search_results .search-details .return__data_detail .departure__detail:before,
.air__search_results .search-details .return__data_detail .departure__detail:after,
.air__search_results .search-details .duration__data_detail .departure__detail:before,
.air__search_results .search-details .duration__data_detail .departure__detail:after {
  content: " ";
  display: table;
}
.air__search_results .search-details .oneway__data_detail .departure__detail:after,
.air__search_results .search-details .return__data_detail .departure__detail:after,
.air__search_results .search-details .duration__data_detail .departure__detail:after {
  clear: both;
}
.air__search_results .search-details .oneway__data_detail .departure__detail_city,
.air__search_results .search-details .return__data_detail .departure__detail_city,
.air__search_results .search-details .duration__data_detail .departure__detail_city {
  margin-left: 2px;
}
.air__search_results .search-details .oneway__data_detail .departure__detail_separator,
.air__search_results .search-details .return__data_detail .departure__detail_separator,
.air__search_results .search-details .duration__data_detail .departure__detail_separator {
  margin-right: 2px;
}
.air__search_results .search-details .oneway__data_detail-heading,
.air__search_results .search-details .return__data_detail-heading,
.air__search_results .search-details .duration__data_detail-heading {
  color: #087dc2;
}
.air__search_results .search-details .oneway__data_detail-period,
.air__search_results .search-details .return__data_detail-period,
.air__search_results .search-details .duration__data_detail-period {
  font-family: "Open Sans";
}
.air__search_results .search-details .oneway__data_icon span.glyphicon.glyphicon-plane {
  transform: rotate(45deg);
  top: 5px;
}
.air__search_results .search-details .return__data_icon span.glyphicon.glyphicon-plane {
  transform: rotate(-45deg);
  top: 5px;
}
.air__search_results .search-details .traveller__row:before, .air__search_results .search-details .traveller__row:after {
  content: " ";
  display: table;
}
.air__search_results .search-details .traveller__row:after {
  clear: both;
}
.air__search_results .search-details .traveller__row > li {
  text-align: center;
  width: 33.33%;
  border-left: 2px solid #c7e5f6;
  padding-top: 9px;
  padding-bottom: 9px;
  float: left;
}
.air__search_results .search-details .traveller__row > li:last-child {
  border-right: 2px solid #c7e5f6;
}

.discounted-fare-text {
  float: left;
  color: red;
  padding-top: 0.3rem;
  position: relative;
  left: 25%;
}

.air__search_results .recommended-product {
  position: absolute;
  z-index: 1;
  transition: 0.3s;
  border-style: solid;
  width: 0px;
  height: 0px;
  line-height: 0px;
  border-color: #444 transparent transparent;
  border-width: 40px 40px 0px 0px;
}
.air__search_results .recommended-product span {
  transform: rotate(30deg);
  color: #dea310;
  position: absolute;
  top: -38px;
  display: inline-block;
  left: 3px;
  font-size: 20px;
}
.air__search_results .search__result_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__search_results .search__result_main:before, .air__search_results .search__result_main:after {
  content: " ";
  display: table;
}
.air__search_results .search__result_main:after {
  clear: both;
}
.air__search_results .search__result_main .content {
  padding-left: 0;
}
.air__search_results .search__result_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__search_results .search__result_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__search_results .search__result_wrapper {
    max-width: 1400px;
  }
}
.air__search_results .search__result_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__search_results .search__result_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.air__search_results .search__result_row:before, .air__search_results .search__result_row:after {
  content: " ";
  display: table;
}
.air__search_results .search__result_row:after {
  clear: both;
}
.air__search_results .search__result_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.air__search_results .search__result_card:before, .air__search_results .search__result_card:after {
  content: " ";
  display: table;
}
.air__search_results .search__result_card:after {
  clear: both;
}
.air__search_results .search__result_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .search__result_footer:before, .air__search_results .search__result_footer:after {
  content: " ";
  display: table;
}
.air__search_results .search__result_footer:after {
  clear: both;
}
.air__search_results .search__result_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.air__search_results .search__result_card:before, .air__search_results .search__result_card:after {
  content: " ";
  display: table;
}
.air__search_results .search__result_card:after {
  clear: both;
}
.air__search_results .search__result_row {
  position: relative;
}
.air__search_results .search__result_tax-header {
  margin-top: -22px;
  margin-left: 25%;
  margin-right: 15px;
  padding: 0.25rem;
  color: #1d771d;
  background-color: #F5D76E;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}
.air__search_results .search__result_tax-header:before, .air__search_results .search__result_tax-header:after {
  content: " ";
  display: table;
}
.air__search_results .search__result_tax-header:after {
  clear: both;
}
.air__search_results .search__result_tax-header .tax-message {
  font-size: 0.9rem;
  padding-left: 0.25rem;
}
.air__search_results .search__result_header {
  text-transform: uppercase;
  color: #087dc2;
  border-bottom: 1px solid #aac7d8;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  margin-bottom: 0.375rem;
  margin-left: -0.9375rem;
  margin-right: -15px;
}
.air__search_results .search__result_header-travel-dates {
  float: right;
}
.air__search_results .search__result_flight, .air__search_results .search__result_price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.air__search_results .search__result_price {
  padding-left: 10px;
  padding-right: 10px;
}
.air__search_results .search__result_price .reissue-result {
  text-align: center;
  display: flex;
  text-transform: none;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-right: 0.625rem;
  color: #087dc2;
}
.air__search_results .search__result_price .reissue-result__error-message {
  align-items: end;
  padding-bottom: 0.625rem;
  color: #ec2227;
}
.air__search_results .search__result_trip {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #ccebfd;
  height: 100%;
}
.air__search_results .search__result_trip .trip {
  float: left;
  width: 100%;
}
.air__search_results .search__result_from, .air__search_results .search__result_to, .air__search_results .search__result_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
}
.air__search_results .result__fname {
  text-transform: capitalize;
}
.air__search_results .result__plane {
  color: #087dc2;
}
.air__search_results .result__flight, .air__search_results .result__from, .air__search_results .result__to, .air__search_results .result__duration, .air__search_results .result__price {
  padding-top: 0.9375rem;
  min-height: 100px;
}
.air__search_results .result__flight, .air__search_results .result__duration {
  text-align: center;
}
.air__search_results .result__flight {
  width: 100%;
}
.air__search_results .result__flight:before, .air__search_results .result__flight:after {
  content: " ";
  display: table;
}
.air__search_results .result__flight:after {
  clear: both;
}
.air__search_results .result__flight_image-container {
  max-width: 120px;
  margin: 0 auto;
}
.air__search_results .result__flight_image-container .image-container__image {
  max-width: 28px;
  max-height: 30px;
  display: inline-block;
}
.air__search_results .result__from {
  text-align: left;
}
.air__search_results .result__to {
  text-align: right;
}
.air__search_results .result__from_time, .air__search_results .result__from_date, .air__search_results .result__to_time, .air__search_results .result__to_date {
  font-family: "Open Sans";
}
.air__search_results .result__from_time, .air__search_results .result__to_time {
  font-size: 1.375rem;
  color: #087dc2;
  font-weight: 400;
}
.air__search_results .result__duration_time {
  font-size: 1.375rem;
  font-family: "Open Sans";
  font-weight: 400;
  color: #f48083;
  text-transform: lowercase;
}
.air__search_results .result__duration_type {
  position: relative;
}
.air__search_results .result__duration_type:before, .air__search_results .result__duration_type:after {
  width: 80px;
  content: "";
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  top: 50%;
}
.air__search_results .result__duration_type:before {
  margin-right: -20px;
  right: 100%;
}
.air__search_results .result__duration_type:after {
  margin-left: -20px;
  left: 100%;
}
.air__search_results .result__duration_legs {
  font-size: 0.625rem;
}
.air__search_results .result__flexi-info-icon {
  font-size: 1rem;
  color: #087dc2;
  padding-left: 0.5rem;
}
.air__search_results .result__price_amount, .air__search_results .result__price_amount-with-adhoc {
  font-size: 1.125rem;
  font-family: "Open Sans";
  color: #ec2227;
}
.air__search_results .result__price_amount-with-adhoc {
  color: #0000FF;
}
.air__search_results .result__price_adhoc-amount {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.air__search_results .result__price_offer-price {
  position: absolute;
  bottom: 0;
}
.air__search_results .result__price_offer-price .offer-price__title {
  color: #087dc2;
  text-transform: capitalize;
}
.air__search_results .result__price_offer-price .offer-price__amount {
  color: #ec2227;
}
.air__search_results .result__flight_details_button, .air__search_results .result__extra_details_text {
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  padding: 0.3125rem 0;
  text-align: right;
  padding-bottom: 0.125rem;
}
.air__search_results .result__extra_details_lodgecard {
  width: 6%;
  font-size: 1.125rem;
}
.air__search_results .result__flight_details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.air__search_results .result__flight_details_button {
  width: 100%;
  background-color: #c7e5f6;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-align: center;
  color: #087dc2;
  cursor: pointer;
}
.air__search_results .result__flight_details_button:hover, .air__search_results .result__flight_details_button:active, .air__search_results .result__flight_details_button:focus {
  color: #000;
  text-decoration: none;
}
.air__search_results .result__flight_details_button:hover {
  text-decoration: underline;
}
.air__search_results .result__extra_details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  background-color: #f0f5f8;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #ccebfd;
}
.air__search_results .result__extra_details_text {
  float: right;
  color: #087dc2;
  position: relative;
  display: inline-flex;
}
.air__search_results .result__extra_details_text .fare-type {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 9rem;
  overflow: hidden;
  color: #087dc2;
}
.air__search_results .result__extra_details_text .supplier-type {
  padding-left: 1rem;
}
.air__search_results .result__extra_details_llf-info {
  font-size: 0.625rem;
  float: right;
  color: #087dc2;
}
.air__search_results .result__extra_details_llf-info .llf-info__button {
  background-color: #f0f5f8;
}
.air__search_results .result__extra_details_highlight-duplicate {
  float: left;
  font-size: 1.2rem;
  color: #ec2227;
  margin-right: 0.5rem;
}
.air__search_results .result__extra_details_highlight-duplicate .highlight-duplicate__button {
  background-color: #f0f5f8;
}
.air__search_results .result__book, .air__search_results .result__select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.air__search_results .result__book_button, .air__search_results .result__select_button {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
.air__search_results .result__book_button:focus, .air__search_results .result__book_button.focus, .air__search_results .result__book_button:active:focus, .air__search_results .result__book_button:active.focus, .air__search_results .result__book_button.active:focus, .air__search_results .result__book_button.active.focus, .air__search_results .result__select_button:focus, .air__search_results .result__select_button.focus, .air__search_results .result__select_button:active:focus, .air__search_results .result__select_button:active.focus, .air__search_results .result__select_button.active:focus, .air__search_results .result__select_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.air__search_results .result__book_button:hover, .air__search_results .result__book_button:focus, .air__search_results .result__book_button.focus, .air__search_results .result__select_button:hover, .air__search_results .result__select_button:focus, .air__search_results .result__select_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.air__search_results .result__book_button:active, .air__search_results .result__book_button.active, .air__search_results .result__select_button:active, .air__search_results .result__select_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.air__search_results .result__book_button.disabled, .air__search_results .result__book_button[disabled], fieldset[disabled] .air__search_results .result__book_button, .air__search_results .result__select_button.disabled, .air__search_results .result__select_button[disabled], fieldset[disabled] .air__search_results .result__select_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.air__search_results .result__select {
  float: right;
  height: 0px;
}
.air__search_results .result__select_button {
  background-color: #087dc2;
}
.air__search_results .result__select_button:hover, .air__search_results .result__select_button:focus, .air__search_results .result__select_button:active {
  background-color: #087dc2;
}
.air__search_results .result__select_button-active {
  color: #087dc2;
  background-color: #c7e5f6;
}
.air__search_results .result__select_button-active:hover, .air__search_results .result__select_button-active:focus, .air__search_results .result__select_button-active:active {
  background-color: #c7e5f6;
}
.air__search_results .select-button__triangle {
  position: relative;
  margin-left: 5px;
  top: 10px;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-top: 7px solid #fff;
}
.air__search_results .select-button__triangle-active {
  position: relative;
  margin-left: 5px;
  top: 10px;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-bottom: 7px solid #fff;
}

.air-result-price-status {
  position: absolute;
  width: 18%;
  bottom: 0px;
  text-align: center;
}
.air-result-price-status__available, .air-result-price-status__unavailable, .air-result-price-status__price-changed {
  margin: 0 auto;
  max-width: 150px;
  color: #FF7000;
}
.air-result-price-status__available {
  color: #3c763d;
}
.air-result-price-status__unavailable {
  color: #dd4b39;
}

.popover-width {
  min-width: 150px;
  color: #838383;
}

.pnr-search-results__fare-result-header {
  padding-top: 0.5rem;
}

.air-port-message-info-icons {
  font-size: 1rem;
  color: #ec2227;
  padding-left: 0.5rem;
}

.air-port-message-modal__mesagge {
  color: #fff;
  background-color: #087dc2;
  border: 0;
  border-radius: 0;
  font-size: 0.75rem;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  padding: 7px;
}
.air-port-message-modal__text {
  font-size: 0.75rem;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  line-height: 3;
  color: #000000d4;
}

.modal-open .air-port-message-modal.modal {
  overflow-x: hidden;
  overflow-y: hidden;
  width: auto;
  height: auto;
}

.air__search_results .two-pane-search__result_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__search_results .two-pane-search__result_main:before, .air__search_results .two-pane-search__result_main:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__result_main:after {
  clear: both;
}
.air__search_results .two-pane-search__result_main .content {
  padding-left: 0;
}
.air__search_results .two-pane-search__result_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__search_results .two-pane-search__result_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__search_results .two-pane-search__result_wrapper {
    max-width: 1400px;
  }
}
.air__search_results .two-pane-search__result_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__search_results .two-pane-search__result_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.air__search_results .two-pane-search__result_row:before, .air__search_results .two-pane-search__result_row:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__result_row:after {
  clear: both;
}
.air__search_results .two-pane-search__result_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.air__search_results .two-pane-search__result_card:before, .air__search_results .two-pane-search__result_card:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__result_card:after {
  clear: both;
}
.air__search_results .two-pane-search__result_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__result_footer:before, .air__search_results .two-pane-search__result_footer:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__result_footer:after {
  clear: both;
}
.air__search_results .two-pane-search__result_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.13);
  margin-bottom: 0;
  position: relative;
}
.air__search_results .two-pane-search__result_detail {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  margin-bottom: 1%;
}
.air__search_results .two-pane-search__result_header {
  text-transform: uppercase;
  color: #087dc2;
  border-bottom: 1px solid #aac7d8;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  margin-bottom: 0.375rem;
  margin-left: -0.9375rem;
  margin-right: -15px;
}
.air__search_results .two-pane-search__result_card .recommended-product {
  left: 0;
  border-width: 30px 30px 0px 0px;
}
.air__search_results .two-pane-search__result_card .recommended-product span {
  top: -29px;
  font-size: 15px;
}
.air__search_results .two-pane-search__result_row {
  float: left;
}
.air__search_results .two-pane-search__result_flight, .air__search_results .two-pane-search__result_price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: 28.66667%;
}
.air__search_results .two-pane-search__result_button {
  width: 5%;
}
.air__search_results .two-pane-search__result_radiobutton {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-top: 1rem;
  padding-left: 15px;
}
.air__search_results .two-pane-search__result_checkbox {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-top: 1rem;
  padding-left: 0;
}
.air__search_results .two-pane-search__result_price {
  padding-left: 10px;
  padding-right: 10px;
}
.air__search_results .two-pane-search__result_trip {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #ccebfd;
  width: 56%;
}
.air__search_results .two-pane-search__result_from, .air__search_results .two-pane-search__result_to, .air__search_results .two-pane-search__result_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 33.333%;
  width: 49.33333%;
}
.air__search_results .two-pane-search__result_flexi-info-icon {
  font-size: 1rem;
  color: #087dc2;
  padding-left: 1.4rem;
}
.air__search_results .two-pane-search__result_flexi-img {
  max-width: 15px;
  max-height: 15px;
}
.air__search_results .two-pane-search__detail_row {
  float: left;
  width: 108%;
}
.air__search_results .two-pane-search__detail_flight, .air__search_results .two-pane-search__detail_price {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-top: 0.7rem;
  padding-right: 0rem;
  width: 16.66667%;
}
.air__search_results .two-pane-search__detail_button {
  width: 5%;
}
.air__search_results .two-pane-search__detail_radiobutton {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-top: 2.9375rem;
}
.air__search_results .two-pane-search__detail_air__search-form-container {
  background-image: url(../../../images/hero-image-3.jpg);
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  background-size: cover;
  background-position: center left;
  min-height: 100vh;
}
.air__search_results .two-pane-search__detail_air__search-form-container_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__search_results .two-pane-search__detail_air__search-form-container_main:before, .air__search_results .two-pane-search__detail_air__search-form-container_main:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-form-container_main:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-form-container_main .content {
  padding-left: 0;
}
.air__search_results .two-pane-search__detail_air__search-form-container_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__search_results .two-pane-search__detail_air__search-form-container_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__search_results .two-pane-search__detail_air__search-form-container_wrapper {
    max-width: 1400px;
  }
}
.air__search_results .two-pane-search__detail_air__search-form-container_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__search_results .two-pane-search__detail_air__search-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-wrapper:before, .air__search_results .two-pane-search__detail_air__search-wrapper:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-wrapper:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-form {
  background-color: #fff;
  padding: 1.875rem;
  margin: 0 auto;
  min-height: 300px;
}
.air__search_results .two-pane-search__detail_air__search-form_header {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
.air__search_results .two-pane-search__detail_air__page-header {
  color: #fff;
  padding-bottom: 1.875rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.25rem;
  margin-top: 0;
}
.air__search_results .two-pane-search__detail_air__search-trip-type, .air__search_results .two-pane-search__detail_air__search-location, .air__search_results .two-pane-search__detail_air__search-date, .air__search_results .two-pane-search__detail_air__search-traveller, .air__search_results .two-pane-search__detail_air__search-multicity, .air__search_results .two-pane-search__detail_air__search-nationality, .air__search_results .two-pane-search__detail_air__search-residence-country, .air__search_results .two-pane-search__detail_air__search-currency, .air__search_results .two-pane-search__detail_air__additioal-option, .air__search_results .two-pane-search__detail_air__pnr-search-location, .air__search_results .two-pane-search__detail_air__product-selection, .air__search_results .two-pane-search__detail_air__advance-search, .air__search_results .two-pane-search__detail_air__advance-search-params, .air__search_results .two-pane-search__detail_air__search-supplier, .air__search_results .two-pane-search__detail_air__search-modify-journey {
  margin-bottom: 1rem;
}
.air__search_results .two-pane-search__detail_air__with-baggage_label {
  padding: 0.3125rem;
}
.air__search_results .two-pane-search__detail_air__pnr-fields {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__pnr-fields:before, .air__search_results .two-pane-search__detail_air__pnr-fields:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__pnr-fields:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__pnr-search-location .input-container {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  margin-bottom: 1rem;
}
.air__search_results .two-pane-search__detail_air__search-trip-type, .air__search_results .two-pane-search__detail_air__search-modify-journey {
  background-color: #c7e5f6;
  padding-top: 0.5rem;
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.air__search_results .two-pane-search__detail_air__search-trip-type:before, .air__search_results .two-pane-search__detail_air__search-trip-type:after, .air__search_results .two-pane-search__detail_air__search-modify-journey:before, .air__search_results .two-pane-search__detail_air__search-modify-journey:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-trip-type:after, .air__search_results .two-pane-search__detail_air__search-modify-journey:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-trip-type .radio-inline, .air__search_results .two-pane-search__detail_air__search-modify-journey .radio-inline {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: auto;
  padding-right: 0;
}
@media (min-width: 1280px) {
  .air__search_results .two-pane-search__detail_air__search-trip-type .radio-inline, .air__search_results .two-pane-search__detail_air__search-modify-journey .radio-inline {
    width: 16.6666666667%;
  }
}
.air__search_results .two-pane-search__detail_air__search-trip-type .radio-inline input[disabled], .air__search_results .two-pane-search__detail_air__search-modify-journey .radio-inline input[disabled] {
  cursor: not-allowed;
}
.air__search_results .two-pane-search__detail_air__search-modify-journey .modify-journey {
  float: right;
  padding-right: 0.8rem;
}
.air__search_results .two-pane-search__detail_air__search-modify-journey .modify-journey__button {
  background: transparent;
  font-weight: 600;
}
.air__search_results .two-pane-search__detail_air__search-location {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-location:before, .air__search_results .two-pane-search__detail_air__search-location:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-location:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-location .search-location_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search_results .two-pane-search__detail_air__search-location .search-location_search-text {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search_results .two-pane-search__detail_air__search-date, .air__search_results .two-pane-search__detail_air__hub-surcharge, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-date:before, .air__search_results .two-pane-search__detail_air__search-date:after, .air__search_results .two-pane-search__detail_air__hub-surcharge:before, .air__search_results .two-pane-search__detail_air__hub-surcharge:after, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates:before, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-date:after, .air__search_results .two-pane-search__detail_air__hub-surcharge:after, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-date .search-date_select, .air__search_results .two-pane-search__detail_air__search-date_baggage-one-way, .air__search_results .two-pane-search__detail_air__hub-surcharge .search-date_select, .air__search_results .two-pane-search__detail_air__hub-surcharge_baggage-one-way, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates .search-date_select, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates_baggage-one-way {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search_results .two-pane-search__detail_air__search-date_baggage-one-way .baggage-label, .air__search_results .two-pane-search__detail_air__hub-surcharge_baggage-one-way .baggage-label, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates_baggage-one-way .baggage-label {
  margin-top: 1.75rem;
  padding: 0.3125rem;
}
.air__search_results .two-pane-search__detail_air__search-date_baggage-round-multicity .baggage-label, .air__search_results .two-pane-search__detail_air__hub-surcharge_baggage-round-multicity .baggage-label, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates_baggage-round-multicity .baggage-label {
  padding: 0.3125rem;
}
.air__search_results .two-pane-search__detail_air__search-date_hotel-dates .search-date_select, .air__search_results .two-pane-search__detail_air__hub-surcharge_hotel-dates .search-date_select, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates_hotel-dates .search-date_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  margin-right: -13px;
  width: 35%;
}
.air__search_results .two-pane-search__detail_air__search-date .date-field-with-duration, .air__search_results .two-pane-search__detail_air__hub-surcharge .date-field-with-duration, .air__search_results .two-pane-search__detail_air__search-date_hotel-dates .date-field-with-duration {
  width: 40%;
}
.air__search_results .two-pane-search__detail_air__search-date-verticle {
  margin-left: -15px;
  margin-right: -15px;
  width: 50%;
  float: left;
  padding-right: 1rem;
  margin-right: 0px;
  margin-left: 0px;
}
.air__search_results .two-pane-search__detail_air__search-date-verticle:before, .air__search_results .two-pane-search__detail_air__search-date-verticle:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-date-verticle:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-date-verticle .date-range-picker__from-date, .air__search_results .two-pane-search__detail_air__search-date-verticle .date-range-picker__to-date {
  margin-bottom: 1rem;
}
.air__search_results .two-pane-search__detail_air__hub-surcharge-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.air__search_results .two-pane-search__detail_air__hub-surcharge-container:before, .air__search_results .two-pane-search__detail_air__hub-surcharge-container:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__hub-surcharge-container:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-nationality, .air__search_results .two-pane-search__detail_air__search-residence-country {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-nationality:before, .air__search_results .two-pane-search__detail_air__search-nationality:after, .air__search_results .two-pane-search__detail_air__search-residence-country:before, .air__search_results .two-pane-search__detail_air__search-residence-country:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-nationality:after, .air__search_results .two-pane-search__detail_air__search-residence-country:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-currency {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-currency:before, .air__search_results .two-pane-search__detail_air__search-currency:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-currency:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__air-select-nationality {
  margin-top: 1rem;
  display: inline-block;
  width: 100%;
}
.air__search_results .two-pane-search__detail_air__search-multicity-row, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-dates, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-multicity-row:before, .air__search_results .two-pane-search__detail_air__search-multicity-row:after, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-dates:before, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-dates:after, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-search-date:before, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-search-date:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-multicity-row:after, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-dates:after, .air__search_results .two-pane-search__detail_air__search-multicity_hotel-search-date:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-multicity_hotel-dates .hotel-dates__room-info_extra-bed {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  padding-top: 7px;
  width: 75%;
}
.air__search_results .two-pane-search__detail_air__search-multicity_hotel-dates .hotel-dates__search-date {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-multicity_location {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  width: 70%;
}
.air__search_results .two-pane-search__detail_air__search-multicity_location .multicity_autosuggestion {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-left: 0px;
}
.air__search_results .two-pane-search__detail_air__search-multicity_location .multicity_autosuggestion .search-location_autosuggestion .itemcontainer {
  left: 0px;
  right: 0px;
}
.air__search_results .two-pane-search__detail_air__search-multicity_location-segmentwise-cabin-class {
  width: 50%;
  padding-right: 0rem;
}
.air__search_results .two-pane-search__detail_air__search-multicity_location-segmentwise-modify {
  width: 60%;
  padding-right: 0rem;
}
.air__search_results .two-pane-search__detail_air__search-multicity_date {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  width: 28%;
}
.air__search_results .two-pane-search__detail_air__search-multicity_date .search-date_select {
  width: 100%;
}
.air__search_results .two-pane-search__detail_air__search-multicity_date-segmentwise-cabin-class {
  padding-left: 0rem;
}
.air__search_results .two-pane-search__detail_air__search-multicity_cabin-class {
  float: left;
  width: 20%;
}
.air__search_results .two-pane-search__detail_air__search-multicity_flight-close {
  float: right;
  color: #838383;
  padding-top: 32px;
  cursor: pointer;
}
.air__search_results .two-pane-search__detail_air__search-multicity_modify {
  float: left;
  width: 10%;
  display: block;
  padding-top: 2rem;
  font-size: 0.875rem;
}
.air__search_results .two-pane-search__detail_air__search-multicity_modify button {
  font-weight: 600;
  background: transparent;
  color: #ec2227;
  padding: 0;
}
.air__search_results .two-pane-search__detail_air__search-multicity_modify button[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.air__search_results .two-pane-search__detail_air__search-traveller {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-traveller:before, .air__search_results .two-pane-search__detail_air__search-traveller:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-traveller:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-traveller:before, .air__search_results .two-pane-search__detail_air__search-traveller:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-traveller:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-traveller_adult, .air__search_results .two-pane-search__detail_air__search-traveller_child, .air__search_results .two-pane-search__detail_air__search-traveller_infant, .air__search_results .two-pane-search__detail_air__search-traveller_class {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.air__search_results .two-pane-search__detail_air__search-traveller_adult input, .air__search_results .two-pane-search__detail_air__search-traveller_child input, .air__search_results .two-pane-search__detail_air__search-traveller_infant input, .air__search_results .two-pane-search__detail_air__search-traveller_class input {
  text-align: center;
}
.air__search_results .two-pane-search__detail_air__search-traveller_round-trip-class {
  width: 50%;
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-traveller_round-trip-class .round-trip-class__cabin-class {
  float: left;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
  width: 100%;
}
.air__search_results .two-pane-search__detail_air__search-traveller_luggage {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-bottom: 1rem;
}
.air__search_results .two-pane-search__detail_air__advance-search_title {
  background-color: #f3f9fd;
  padding: 0.5rem 0.5rem 0rem;
  cursor: pointer;
}
.air__search_results .two-pane-search__detail_air__advance-search-params {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__advance-search-params:before, .air__search_results .two-pane-search__detail_air__advance-search-params:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__advance-search-params:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__advance-search-params_stops, .air__search_results .two-pane-search__detail_air__advance-search-params_refundable {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search_results .two-pane-search__detail_air__search-supplier {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 1rem;
}
.air__search_results .two-pane-search__detail_air__search-supplier:before, .air__search_results .two-pane-search__detail_air__search-supplier:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-supplier:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__supplier-selection label {
  padding-right: 2rem;
}
.air__search_results .two-pane-search__detail_air__additioal-option {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__additioal-option:before, .air__search_results .two-pane-search__detail_air__additioal-option:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__additioal-option:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__additioal-option_content {
  color: #087dc2;
  background-color: #f3f9fd;
  font-size: 0.875rem;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 10px;
  padding: 5px;
}
.air__search_results .two-pane-search__detail_air__search-submit {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-search__detail_air__search-submit:before, .air__search_results .two-pane-search__detail_air__search-submit:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-submit:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-submit_button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  float: right;
}
.air__search_results .two-pane-search__detail_air__search-submit_button .submit_button {
  padding: 0.375rem 0.9375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  text-transform: uppercase;
  width: 100%;
}
.air__search_results .two-pane-search__detail_air__search-submit_button .submit_button:focus, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button.focus, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button:active:focus, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button:active.focus, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button.active:focus, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.air__search_results .two-pane-search__detail_air__search-submit_button .submit_button:hover, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button:focus, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.air__search_results .two-pane-search__detail_air__search-submit_button .submit_button:active, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.air__search_results .two-pane-search__detail_air__search-submit_button .submit_button.disabled, .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button[disabled], fieldset[disabled] .air__search_results .two-pane-search__detail_air__search-submit_button .submit_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.air__search_results .two-pane-search__detail_air__search-form_container input[type=text], .air__search_results .two-pane-search__detail_air__search-form_container input[type=number],
.air__search_results .two-pane-search__detail_air__search-form_container select {
  width: 100%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
}
.air__search_results .two-pane-search__detail_air__search-form_container input[type=text], .air__search_results .two-pane-search__detail_air__search-form_container input[type=number] {
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 34px;
  position: relative;
}
.air__search_results .two-pane-search__detail_air__search-form_container .number-stepper {
  max-width: 250px;
}
.air__search_results .two-pane-search__detail_air__search-form_container .number-stepper input[type=text] {
  text-align: center;
}
.air__search_results .two-pane-search__detail_air__search-form_container .number-stepper button[disabled] {
  cursor: not-allowed;
  color: #818a91;
}
.air__search_results .two-pane-search__detail_air__search-form_container .number-stepper button[disabled]:hover {
  background-color: #818a91;
  color: #fff;
}
.air__search_results .two-pane-search__detail_air__search-form_container label {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
}
.air__search_results .two-pane-search__detail_air__advertise {
  padding: 1.875rem;
  background-color: #fff;
  margin-top: 2.375rem;
  min-height: 300px;
}
.air__search_results .two-pane-search__detail_air__advertise p {
  margin-bottom: 0;
}
.air__search_results .two-pane-search__detail_air__search-time, .air__search_results .two-pane-search__detail_air__search-date, .air__search_results .two-pane-search__detail_air__input-field {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 1rem;
}
.air__search_results .two-pane-search__detail_air__search-time:before, .air__search_results .two-pane-search__detail_air__search-time:after, .air__search_results .two-pane-search__detail_air__search-date:before, .air__search_results .two-pane-search__detail_air__search-date:after, .air__search_results .two-pane-search__detail_air__input-field:before, .air__search_results .two-pane-search__detail_air__input-field:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-search__detail_air__search-time:after, .air__search_results .two-pane-search__detail_air__search-date:after, .air__search_results .two-pane-search__detail_air__input-field:after {
  clear: both;
}
.air__search_results .two-pane-search__detail_air__search-time_select, .air__search_results .two-pane-search__detail_air__search-date_select, .air__search_results .two-pane-search__detail_air__input-field_select {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.air__search_results .two-pane-search__detail_air__search-time_half, .air__search_results .two-pane-search__detail_air__search-date_half, .air__search_results .two-pane-search__detail_air__input-field_half {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
}
.air__search_results .two-pane-air__detail {
  position: relative;
}
.air__search_results .two-pane-air__detail_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__search_results .two-pane-air__detail_main:before, .air__search_results .two-pane-air__detail_main:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-air__detail_main:after {
  clear: both;
}
.air__search_results .two-pane-air__detail_main .content {
  padding-left: 0;
}
.air__search_results .two-pane-air__detail_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__search_results .two-pane-air__detail_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__search_results .two-pane-air__detail_wrapper {
    max-width: 1400px;
  }
}
.air__search_results .two-pane-air__detail_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__search_results .two-pane-air__detail_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.air__search_results .two-pane-air__detail_row:before, .air__search_results .two-pane-air__detail_row:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-air__detail_row:after {
  clear: both;
}
.air__search_results .two-pane-air__detail_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.air__search_results .two-pane-air__detail_card:before, .air__search_results .two-pane-air__detail_card:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-air__detail_card:after {
  clear: both;
}
.air__search_results .two-pane-air__detail_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.air__search_results .two-pane-air__detail_footer:before, .air__search_results .two-pane-air__detail_footer:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-air__detail_footer:after {
  clear: both;
}
.air__search_results .two-pane-air__detail_radiobutton, .air__search_results .two-pane-air__detail_checkbox {
  position: absolute;
  top: 3rem;
}
.air__search_results .two-pane-air__detail_radiobutton {
  left: 10px;
}
.air__search_results .two-pane-air__detail_checkbox {
  right: 10px;
}
.air__search_results .two-pane-air__detail_card {
  margin-bottom: 1%;
  padding: 1rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data {
  width: 100%;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__airline-info {
  width: 22%;
  float: left;
  text-transform: capitalize;
  max-height: 1.125rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__amount {
  width: 30%;
  padding-top: 0.3125rem;
  float: left;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__amount_currency {
  float: left;
  padding-right: 0.3125rem;
  padding-top: 0.375rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__amount_total-price, .air__search_results .two-pane-air__detail_card .two-pane-row-data__amount_total-price-with-adhoc {
  font-size: 1.125rem;
  color: #ec2227;
  font-family: "Open Sans";
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__amount_total-price-with-adhoc {
  color: #0000FF;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__amount_offer-price {
  text-align: center;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__amount_offer-price .offer-price__title {
  color: #087dc2;
  text-transform: capitalize;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__amount_offer-price .offer-price__amount {
  color: #ec2227;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__loader {
  width: 30%;
  padding-top: 0.3125rem;
  float: left;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__loader_msg {
  font-size: 0.68rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__fetch-fare-button {
  width: 100%;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  padding: 0;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__layover-time {
  width: 20%;
  padding-top: 0.5625rem;
  float: left;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__layover-time_time-details {
  color: #3b5998;
  padding-left: 0.5rem;
  font-family: "Open Sans";
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__layover-time_stops {
  font-size: 0.625rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__layover-time_stops .one-stop {
  padding-left: 0.25rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__layover-time_stops .multi-stop {
  padding-left: 0.0625rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__layover-time_aircraft {
  font-size: 0.585rem;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__departure-details, .air__search_results .two-pane-air__detail_card .two-pane-row-data__arrival-details {
  width: 14%;
  padding-top: 0.3125rem;
  float: left;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__departure-details_time, .air__search_results .two-pane-air__detail_card .two-pane-row-data__arrival-details_time {
  font-size: 1rem;
  font-family: "Open Sans";
  color: #087dc2;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__departure-details_date, .air__search_results .two-pane-air__detail_card .two-pane-row-data__arrival-details_date {
  font-size: 0.595rem;
  font-weight: 700;
  font-family: "Open Sans";
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__llf-info {
  font-size: 0.625rem;
  float: right;
  color: #087dc2;
}
.air__search_results .two-pane-air__detail_card .two-pane-row-data__llf-info .llf-info__button {
  background-color: #fff;
}
.air__search_results .two-pane-air__detail_row {
  float: left;
  width: 100%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  height: 85px;
  padding-top: 0.7rem;
  margin-left: 0;
  background-color: #fff;
}
.air__search_results .two-pane-air__detail_flight_onwardleg {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-top: 0;
  height: 48px;
  padding-left: 0rem;
  padding-right: 0rem;
}
.air__search_results .two-pane-air__detail_flight_onwardleg-image {
  max-width: 30px;
  max-height: 30px;
}
.air__search_results .two-pane-air__detail_flight_returnleg {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-top: 0;
  width: 15%;
  height: 45px;
}
.air__search_results .two-pane-air__detail_onwardleg {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  float: left;
  height: 60px;
  width: 25%;
}
.air__search_results .two-pane-air__detail_returnleg {
  float: left;
  width: 43%;
}
.air__search_results .two-pane-air__detail_flight-totalfare {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: 100%;
}
.air__search_results .two-pane-air__detail_flight-totalfare .flight-totalfare__currency {
  padding-top: 0.375rem;
}
.air__search_results .two-pane-air__detail_flight-totalfare .flight-totalfare__amount {
  color: #ec2227;
  font-family: "Open Sans";
  font-size: 1rem;
}
.air__search_results .two-pane-air__detail_leg {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
}
.air__search_results .two-pane-air__detail_book {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.air__search_results .two-pane-air__detail_flight_fzimage {
  width: 93%;
}
.air__search_results .two-pane-air__detail_data {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  width: 80%;
  padding-right: 0rem;
}
.air__search_results .two-pane__wrapper {
  position: relative;
}
.air__search_results .two-pane__wrapper:before, .air__search_results .two-pane__wrapper:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane__wrapper:after {
  clear: both;
}
.air__search_results .two-pane__wrapper_book-container {
  float: left;
  width: 100%;
}
.air__search_results .two-pane__wrapper_data-content {
  float: left;
}
.air__search_results .two-pane__wrapper_search-details {
  width: 100%;
}
.air__search_results .two-pane__wrapper_search-details .search-result-details {
  width: 100%;
  float: left;
  padding: 1rem;
  padding-bottom: 0;
  padding-top: 0.4375rem;
  margin-bottom: 0.3125rem;
}
.air__search_results .two-pane__wrapper_search-details .search-result-details__flight-icon {
  float: left;
  font-size: 1.375rem;
  padding-right: 0.625rem;
  padding-top: 0.1875rem;
}
.air__search_results .two-pane__wrapper_search-details .search-result-details__search-data_from-city, .air__search_results .two-pane__wrapper_search-details .search-result-details__search-data_to-city {
  color: #3b5998;
  font-size: 0.75rem;
}
.air__search_results .two-pane__wrapper_content {
  float: left;
  width: 100%;
  border-right: 1px solid #ACEDDF;
}
.air__search_results .two-pane__wrapper_title {
  font-size: 0.9rem;
  font-weight: bolder;
  background-color: lightgray;
}
.air__search_results .two-pane__wrapper_title_active {
  font-size: 0.9rem;
  font-weight: bolder;
  background-color: #087dc2;
  text-align: center;
  color: white;
}
.air__search_results .two-pane__wrapper_title_active .filter__content_right .right__arrow-icon {
  color: #087dc2;
}
.air__search_results .two-pane__wrapper_title_active .filter__content_left .left__arrow-icon {
  color: #087dc2;
}
.air__search_results .two-pane__wrapper_title_inactive {
  font-size: 0.9rem;
  font-weight: bolder;
  background-color: lightgray;
  text-align: center;
}
.air__search_results .two-pane__wrapper_sort li {
  width: 28%;
}
.air__search_results .two-pane-flight-details__flight, .air__search_results .two-pane-flight-details__from, .air__search_results .two-pane-flight-details__to, .air__search_results .two-pane-flight-details__duration, .air__search_results .two-pane-flight-details__price {
  padding-top: 0.9375rem;
}
.air__search_results .two-pane-flight-details__fare-basis {
  color: #087dc2;
}
.air__search_results .two-pane-flight-details__fare-rules, .air__search_results .two-pane-flight-details__fare-basis {
  font-size: 0.625rem;
}
.air__search_results .two-pane-flight-details__flight, .air__search_results .two-pane-flight-details__duration {
  text-align: center;
}
.air__search_results .two-pane-flight-details__from {
  text-align: left;
}
.air__search_results .two-pane-flight-details__to {
  text-align: right;
}
.air__search_results .two-pane-flight-details__from_airport, .air__search_results .two-pane-flight-details__from_terminal, .air__search_results .two-pane-flight-details__from_baggage, .air__search_results .two-pane-flight-details__from_aircarft-type, .air__search_results .two-pane-flight-details__to_airport, .air__search_results .two-pane-flight-details__to_terminal, .air__search_results .two-pane-flight-details__to_baggage, .air__search_results .two-pane-flight-details__to_aircarft-type {
  font-size: 0.625rem;
  word-wrap: break-word;
}
.air__search_results .two-pane-flight-details__from_airline, .air__search_results .two-pane-flight-details__to_airline {
  font-size: 0.625rem;
}
.air__search_results .two-pane-flight-details__from_location, .air__search_results .two-pane-flight-details__to_location {
  text-transform: uppercase;
  color: #087dc2;
}
.air__search_results .two-pane-flight-details__from_aircraft, .air__search_results .two-pane-flight-details__to_aircraft {
  text-transform: uppercase;
  color: #087dc2;
}
.air__search_results .two-pane-flight-details__from_time, .air__search_results .two-pane-flight-details__from_date, .air__search_results .two-pane-flight-details__to_time, .air__search_results .two-pane-flight-details__to_date {
  font-family: "Open Sans";
}
.air__search_results .two-pane-flight-details__from_date, .air__search_results .two-pane-flight-details__to_date {
  font-size: 0.875rem;
  color: #838383;
}
.air__search_results .two-pane-flight-details__from_time, .air__search_results .two-pane-flight-details__to_time {
  font-size: 1.125rem;
  color: #087dc2;
  line-height: 1.125rem;
}
.air__search_results .two-pane-flight-details__from_time .to-iata, .air__search_results .two-pane-flight-details__from_time .from-iata, .air__search_results .two-pane-flight-details__to_time .to-iata, .air__search_results .two-pane-flight-details__to_time .from-iata {
  width: 100%;
}
.air__search_results .two-pane-flight-details__from_suitcase, .air__search_results .two-pane-flight-details__to_suitcase {
  color: #087dc2;
  font-size: 1.2rem;
}
.air__search_results .two-pane-flight-details__fid {
  font-family: "Open Sans";
}
.air__search_results .two-pane-flight-details__airline-xid {
  font-family: "Open Sans";
}
.air__search_results .two-pane-flight-details__duration_flight-duration {
  font-size: 0.5625rem;
}
.air__search_results .two-pane-flight-details__duration .flexi-time {
  font-size: 0.75rem;
  color: #55595c;
}
.air__search_results .two-pane-flight-details__duration .flexi-time:before, .air__search_results .two-pane-flight-details__duration .flexi-time:after {
  width: 1.0625rem;
  border: 0.0625rem #b6b6b6 dashed;
  background: #fff;
}
.air__search_results .two-pane-flight-details__duration_time {
  font-size: 1.125rem;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.125rem;
  color: #f48083;
  position: relative;
  text-transform: lowercase;
}
.air__search_results .two-pane-flight-details__duration_time:before, .air__search_results .two-pane-flight-details__duration_time:after {
  width: 60px;
  content: "";
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  top: 50%;
}
.air__search_results .two-pane-flight-details__duration_time:before {
  right: 100%;
}
.air__search_results .two-pane-flight-details__duration_time:after {
  left: 100%;
}
.air__search_results .two-pane-flight-details__duration_wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  padding-left: 0;
  padding-right: 0;
}
.air__search_results .two-pane-flight-details__duration_wrapper:before, .air__search_results .two-pane-flight-details__duration_wrapper:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-flight-details__duration_wrapper:after {
  clear: both;
}
.air__search_results .two-pane-flight-details__duration_duration-block {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.85rem;
}
.air__search_results .two-pane-flight-details__seat_count_red {
  color: #ec2227;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.air__search_results .two-pane-flight-details__seat_count_green {
  color: #908e8e;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.air__search_results .two-pane-flight-details__seat_text {
  color: #087dc2;
  text-transform: capitalize;
}
.air__search_results .two-pane-flight-details__tab-content {
  height: 6.875rem;
}
.air__search_results .two-pane-flight-details__tab-footer {
  background-color: #f0f5f8;
  width: 100%;
}
.air__search_results .two-pane-flight-details__tab-footer:before, .air__search_results .two-pane-flight-details__tab-footer:after {
  content: " ";
  display: table;
}
.air__search_results .two-pane-flight-details__tab-footer:after {
  clear: both;
}
.air__search_results .two-pane-flight-details__tab-footer_flight-details .flight-details-button {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-align: center;
  color: #000;
  float: left;
  cursor: pointer;
  width: 20%;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
  background-color: #c7e5f6;
}
.air__search_results .two-pane-flight-details__tab-footer_extra-details {
  float: right;
  padding: 0.3125rem;
  min-height: 0.0625rem;
  width: 45%;
  text-align: right;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
}
.air__search_results .two-pane-flight-details__tab-footer_supplier-details {
  width: 35%;
  text-overflow: ellipsis;
  float: left;
  overflow: hidden;
  text-align: left;
  padding: 0.125rem 0.625rem;
  padding-top: 0.125rem;
}
.air__search_results .two-pane-flight-details__airline {
  font-size: 0.75rem;
}
.air__search_results .two-pane-flight-details__iata {
  font-size: 0.625rem;
  width: 85%;
}
.air__search_results .two-pane-flight-details__from_time, .air__search_results .two-pane-flight-details__to_time {
  width: 400%;
  font-size: 0.9rem;
}
.air__search_results .two-pane-flight-details__from_departure, .air__search_results .two-pane-flight-details__to_departure {
  padding-right: 15%;
  font-size: 0.9rem;
}
.air__search_results .two-pane-flight-details__duration {
  width: 18%;
  float: left;
  padding-top: 0%;
}
.air__search_results .two-pane-flight-details__duration_wrapper {
  width: 36%;
}
.air__search_results .two-pane-flight-details__duration_time {
  color: #087dc2;
  position: "";
  text-transform: "";
  text-align: left;
  font-weight: 600;
  font-size: 0.9rem;
}
.air__search_results .two-pane-flight-details__duration_time:before, .air__search_results .two-pane-flight-details__duration_time:after {
  width: 0px;
  height: 0px;
}
.air__search_results .filter__content_wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.air__search_results .filter__content_wrapper:before, .air__search_results .filter__content_wrapper:after {
  content: " ";
  display: table;
}
.air__search_results .filter__content_wrapper:after {
  clear: both;
}
.air__search_results .filter__content_child {
  position: relative;
  left: 0%;
}
.air__search_results .filter__content_child:before, .air__search_results .filter__content_child:after {
  content: " ";
  display: table;
}
.air__search_results .filter__content_child:after {
  clear: both;
}
.air__search_results .filter__content_pane {
  float: left;
  width: 50%;
}
.air__search_results .filter__content_pane:before, .air__search_results .filter__content_pane:after {
  content: " ";
  display: table;
}
.air__search_results .filter__content_pane:after {
  clear: both;
}
.air__search_results .filter__content_left {
  position: absolute;
  left: 10px;
  padding-left: 2rem;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 3px;
}
.air__search_results .filter__content_left .left__circle-icon {
  color: #fff;
}
.air__search_results .filter__content_left .left__arrow-icon {
  color: #838383;
}
.air__search_results .filter__content_right {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 3px;
  float: right;
  margin-right: 0.65rem;
}
.air__search_results .filter__content_right .right__circle-icon {
  color: #fff;
}
.air__search_results .filter__content_right .right__arrow-icon {
  color: #838383;
}
.air__search_results .filter__content_header {
  margin-top: 0.125rem;
  cursor: pointer;
}
.air__search_results .filter__content_tabs {
  height: 35px;
}
.air__search_results .filter__content_routes {
  padding-top: 8px;
  padding-left: 35px;
}
.air__search_results .two-pane-detail__flight_fzimage {
  width: 115%;
}
.air__search_results .two-pane-detail__flight_image {
  max-width: 30px;
  max-height: 30px;
}
.air__search_results .two-pane-detail__seat_count_red {
  color: red;
  font-size: 11px;
  font-weight: bold;
}
.air__search_results .two-pane-detail__seat_count_green {
  color: green;
  font-size: 11px;
  font-weight: bold;
}
.air__search_results .two-pane-detail__seat_text {
  color: #087dc2;
}
.air__search_results .two-pane-details__flight {
  max-width: 28px;
  max-height: 30px;
}
.air__search_results .two-pane-details_from, .air__search_results .two-pane-details_duration {
  padding-top: 0.7rem;
}
.air__search_results .two-pane-details_duration_from {
  padding-top: 0rem;
  width: 50%;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  float: left;
  min-height: 1px;
  padding-bottom: 0.2rem;
}
.air__search_results .two-pane-details__filter_activetab {
  color: #fff;
  background-color: #087dc2;
  text-shadow: 0 1px 0 #000;
  display: block;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  top: 1px;
  outline: none;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  float: left;
  cursor: pointer;
}
.air__search_results .two-pane-details__filter_inactivetab {
  display: block;
  padding: 4px 8px;
  color: #666;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  top: 1px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  float: left;
  cursor: pointer;
}
.air__search_results .two-pane-details__filter_result_active {
  color: #fff;
  background-color: #087dc2;
  text-shadow: 0 1px 0 #000;
  padding: 2px 8px;
  color: #666;
  font-size: 13px;
  font-weight: bold;
  outline: none;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.air__search_results .two-pane-details__filter_result_inactive {
  padding: 2px 8px;
  color: #666;
  font-size: 13px;
  font-weight: bold;
  outline: none;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) inset, 0px 1px #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.air__search_results .two-pane-result__faretype {
  overflow: hidden;
  text-overflow: ellipsis;
}
.air__search_results .two-pane-result__faretype_value {
  font-size: 0.6875rem;
}
.air__search_results .two-pane-result__fname {
  text-transform: capitalize;
}
.air__search_results .two-pane-result__flight, .air__search_results .two-pane-result__from, .air__search_results .two-pane-result__to, .air__search_results .two-pane-result__duration, .air__search_results .two-pane-result__price {
  padding-top: 0.7rem;
}
.air__search_results .two-pane-result__flight_llf-info, .air__search_results .two-pane-result__from_llf-info, .air__search_results .two-pane-result__to_llf-info, .air__search_results .two-pane-result__duration_llf-info, .air__search_results .two-pane-result__price_llf-info {
  font-size: 0.625rem;
  float: right;
  color: #087dc2;
}
.air__search_results .two-pane-result__flight_llf-info .llf-info__button, .air__search_results .two-pane-result__from_llf-info .llf-info__button, .air__search_results .two-pane-result__to_llf-info .llf-info__button, .air__search_results .two-pane-result__duration_llf-info .llf-info__button, .air__search_results .two-pane-result__price_llf-info .llf-info__button {
  background-color: #fff;
}
.air__search_results .two-pane-result__flight, .air__search_results .two-pane-result__duration {
  text-align: center;
}
.air__search_results .two-pane-result__flight_image {
  max-width: 28px;
  max-height: 30px;
}
.air__search_results .two-pane-result__from {
  text-align: left;
}
.air__search_results .two-pane-result__to {
  text-align: right;
}
.air__search_results .two-pane-result__from_time, .air__search_results .two-pane-result__from_date, .air__search_results .two-pane-result__to_time, .air__search_results .two-pane-result__to_date {
  font-family: "Open Sans";
  font-size: 0.75rem;
  font-weight: bold;
}
.air__search_results .two-pane-result__from_time, .air__search_results .two-pane-result__to_time {
  font-size: 1.375rem;
  color: #087dc2;
  font-weight: 400;
}
.air__search_results .two-pane-result__duration_time {
  font-size: 1.375rem;
  font-family: "Open Sans";
  font-weight: 400;
  color: #f48083;
  text-transform: lowercase;
}
.air__search_results .two-pane-result__duration_type {
  position: relative;
}
.air__search_results .two-pane-result__duration_type:before, .air__search_results .two-pane-result__duration_type:after {
  width: 80px;
  content: "";
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  top: 50%;
}
.air__search_results .two-pane-result__duration_type:before {
  margin-right: -20px;
  right: 100%;
}
.air__search_results .two-pane-result__duration_type:after {
  margin-left: -20px;
  left: 100%;
}
.air__search_results .two-pane-result__duration_legs {
  font-size: 0.625rem;
}
.air__search_results .two-pane-result__price {
  padding-top: 0.7rem;
}
.air__search_results .two-pane-result__price_llf-info {
  font-size: 0.625rem;
  float: right;
  color: #087dc2;
}
.air__search_results .two-pane-result__price_llf-info .llf-info__button {
  background-color: #fff;
}
.air__search_results .two-pane-result__price_amount, .air__search_results .two-pane-result__price_amount-with-adhoc {
  font-size: 0.8rem;
  font-family: "Open Sans";
  color: #ec2227;
}
.air__search_results .two-pane-result__price_amount-with-adhoc {
  color: #0000FF;
}
.air__search_results .two-pane-result__price_adhoc-amount {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.air__search_results .two-pane-result__flight_details_button, .air__search_results .two-pane-result__extra_details_text {
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 600;
}
.air__search_results .two-pane-result__flight_details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.air__search_results .two-pane-result__flight_details_button {
  width: 100%;
  background-color: #c7e5f6;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  display: block;
  text-align: center;
  color: #000;
}
.air__search_results .two-pane-result__flight_details_button:hover, .air__search_results .two-pane-result__flight_details_button:active, .air__search_results .two-pane-result__flight_details_button:focus {
  color: #000;
  text-decoration: none;
}
.air__search_results .two-pane-result__flight_details_button:hover {
  text-decoration: underline;
}
.air__search_results .two-pane-result__extra_details {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  background-color: #f0f5f8;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #ccebfd;
}
.air__search_results .two-pane-result__extra_details_text {
  float: right;
}
.air__search_results .two-pane-result__book {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.4rem;
  width: 80%;
}
.air__search_results .two-pane-result__book_button {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
.air__search_results .two-pane-result__book_button:focus, .air__search_results .two-pane-result__book_button.focus, .air__search_results .two-pane-result__book_button:active:focus, .air__search_results .two-pane-result__book_button:active.focus, .air__search_results .two-pane-result__book_button.active:focus, .air__search_results .two-pane-result__book_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.air__search_results .two-pane-result__book_button:hover, .air__search_results .two-pane-result__book_button:focus, .air__search_results .two-pane-result__book_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.air__search_results .two-pane-result__book_button:active, .air__search_results .two-pane-result__book_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.air__search_results .two-pane-result__book_button.disabled, .air__search_results .two-pane-result__book_button[disabled], fieldset[disabled] .air__search_results .two-pane-result__book_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.air__search_results .two-pane-result__search_flight {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  float: right;
}
.air__search_results .two-pane-result__search_flight_button {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
.air__search_results .two-pane-result__search_flight_button:focus, .air__search_results .two-pane-result__search_flight_button.focus, .air__search_results .two-pane-result__search_flight_button:active:focus, .air__search_results .two-pane-result__search_flight_button:active.focus, .air__search_results .two-pane-result__search_flight_button.active:focus, .air__search_results .two-pane-result__search_flight_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.air__search_results .two-pane-result__search_flight_button:hover, .air__search_results .two-pane-result__search_flight_button:focus, .air__search_results .two-pane-result__search_flight_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.air__search_results .two-pane-result__search_flight_button:active, .air__search_results .two-pane-result__search_flight_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.air__search_results .two-pane-result__search_flight_button.disabled, .air__search_results .two-pane-result__search_flight_button[disabled], fieldset[disabled] .air__search_results .two-pane-result__search_flight_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.air__search_results .filter__section .pane__section:before, .air__search_results .filter__section .pane__section:after {
  content: " ";
  display: table;
}
.air__search_results .filter__section .pane__section:after {
  clear: both;
}
.air__search_results .filter__section .pane__section_checkbox {
  float: left;
  width: 15%;
}
.air__search_results .filter__section .pane__section_pane-label {
  float: left;
  width: 50%;
  padding-top: 0.375rem;
}
.air__search_results .filter__section .pane__section_pane-label:before, .air__search_results .filter__section .pane__section_pane-label:after {
  content: " ";
  display: table;
}
.air__search_results .filter__section .pane__section_pane-label:after {
  clear: both;
}
.air__search_results .filter__section .pane__section_only-pane-selection {
  float: right;
  cursor: pointer;
  color: #087dc2;
  padding-left: 0.375rem;
  font-weight: 400;
  text-transform: capitalize;
}
.air__search_results .filter__section .pane__section_notification {
  float: left;
  color: #087dc2;
  padding-top: 0.375rem;
  font: 0.625rem;
  width: 100%;
}

.air-port-message-info-icon {
  font-size: 1.4rem;
  color: #ec2227;
  padding-left: 0.5rem;
}

.flight-details, .pnr-flight-details, .flexi-flight-details {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-bottom: 0.625rem;
}
.flight-details .nav, .pnr-flight-details .nav, .flexi-flight-details .nav {
  background-color: #f0f5f8;
  content: "";
  display: table;
  width: 100%;
}
.flight-details .nav-tabs, .pnr-flight-details .nav-tabs, .flexi-flight-details .nav-tabs {
  border-bottom: none;
}
.flight-details .nav-tabs > li > a, .pnr-flight-details .nav-tabs > li > a, .flexi-flight-details .nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  padding: 8px 45px;
  color: #55595c;
  font-size: 0.75rem;
}
.flight-details .nav > li > a:hover,
.flight-details .nav > li > a:focus, .pnr-flight-details .nav > li > a:hover,
.pnr-flight-details .nav > li > a:focus, .flexi-flight-details .nav > li > a:hover,
.flexi-flight-details .nav > li > a:focus {
  background-color: #fff;
}
.flight-details .nav-tabs > li.active > a,
.flight-details .nav-tabs > li.active > a:hover,
.flight-details .nav-tabs > li.active > a:focus, .pnr-flight-details .nav-tabs > li.active > a,
.pnr-flight-details .nav-tabs > li.active > a:hover,
.pnr-flight-details .nav-tabs > li.active > a:focus, .flexi-flight-details .nav-tabs > li.active > a,
.flexi-flight-details .nav-tabs > li.active > a:hover,
.flexi-flight-details .nav-tabs > li.active > a:focus {
  color: #ec2227;
  background-color: #fff;
  border: none;
  cursor: default;
}
.flight-details__header, .pnr-flight-details__header, .flexi-flight-details__header {
  background-color: #f0f5f8;
  border-left: 10px solid #c7e5f6;
  border-right: 10px solid #c7e5f6;
  border-top: 10px solid #c7e5f6;
}
.flight-details__list:before, .flight-details__list:after, .pnr-flight-details__list:before, .pnr-flight-details__list:after, .flexi-flight-details__list:before, .flexi-flight-details__list:after {
  content: " ";
  display: table;
}
.flight-details__list:after, .pnr-flight-details__list:after, .flexi-flight-details__list:after {
  clear: both;
}
.flight-details__list > li, .pnr-flight-details__list > li, .flexi-flight-details__list > li {
  padding-top: 5px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}
.flight-details__list > li > a, .pnr-flight-details__list > li > a, .flexi-flight-details__list > li > a {
  color: #838383;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
}
.flight-details__tab-content, .pnr-flight-details__tab-content, .flexi-flight-details__tab-content {
  background-color: #fff;
  border-left: 10px solid #c7e5f6;
  border-right: 10px solid #c7e5f6;
  border-bottom: 10px solid #c7e5f6;
  border-top: 10px solid #c7e5f6;
}
.flight-details__change-fare-tab-content, .pnr-flight-details__change-fare-tab-content, .flexi-flight-details__change-fare-tab-content {
  background-color: #fff;
  border-left: 10px solid #087dc2;
  border-right: 10px solid #087dc2;
  border-bottom: 10px solid #087dc2;
  border-top: 10px solid #087dc2;
}
.flight-details__change-fare-tab-content_fare, .pnr-flight-details__change-fare-tab-content_fare, .flexi-flight-details__change-fare-tab-content_fare {
  height: auto;
  min-height: 1rem !important;
  padding: 0;
}
.flight-details__change-fare-tab-content_fare-type, .pnr-flight-details__change-fare-tab-content_fare-type, .flexi-flight-details__change-fare-tab-content_fare-type {
  color: #087dc2;
}
.flight-details__change-fare-tab-content_book, .pnr-flight-details__change-fare-tab-content_book, .flexi-flight-details__change-fare-tab-content_book {
  width: 100% !important;
}
.flight-details .active, .pnr-flight-details .active, .flexi-flight-details .active {
  background-color: #fff;
}
.flight-details .active a, .pnr-flight-details .active a, .flexi-flight-details .active a {
  color: #ec2227;
}
.flight-details .active a:hover, .pnr-flight-details .active a:hover, .flexi-flight-details .active a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.flexi-flight-details {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.flexi-flight-details .nav-tabs > li > a {
  padding: 8px 10px;
}
.flexi-flight-details__extra-header .nav-tabs > li > a {
  padding: 8px 3.9px;
}

.nearby-airport-modal {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
}
.nearby-airport-modal:before, .nearby-airport-modal:after {
  content: " ";
  display: table;
}
.nearby-airport-modal:after {
  clear: both;
}
.nearby-airport-modal__content {
  border: 2px solid #087dc2;
  padding: 0.9375rem;
}
.nearby-airport-modal__content_text {
  color: #087dc2;
  padding-bottom: 0.625rem;
  font-size: 0.875rem;
  margin-top: 5%;
  text-align: left;
}
.nearby-airport-modal__content_buttons, .nearby-airport-modal__content .buttons {
  min-width: 12.5rem;
  padding: 0.9375rem;
  width: 100%;
}
.nearby-airport-modal__content_buttons:before, .nearby-airport-modal__content_buttons:after, .nearby-airport-modal__content .buttons:before, .nearby-airport-modal__content .buttons:after {
  content: " ";
  display: table;
}
.nearby-airport-modal__content_buttons:after, .nearby-airport-modal__content .buttons:after {
  clear: both;
}
.nearby-airport-modal__content_buttons__close-button, .nearby-airport-modal__content_buttons__okay-button, .nearby-airport-modal__content .buttons__close-button, .nearby-airport-modal__content .buttons__okay-button {
  color: #087dc2;
  width: 12%;
  padding: 0.375rem;
  background-color: #c7e5f6;
  font-weight: 600;
}
.nearby-airport-modal__content_buttons__okay-button, .nearby-airport-modal__content .buttons__okay-button {
  float: right;
}
.nearby-airport-modal__content_buttons__close-button, .nearby-airport-modal__content .buttons__close-button {
  float: left;
}
.nearby-airport-modal__content_close {
  float: right;
  cursor: pointer;
  color: #087dc2;
}

.search__detail_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.search__detail_row:before, .search__detail_row:after {
  content: " ";
  display: table;
}
.search__detail_row:after {
  clear: both;
}
.search__detail_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.search__detail_card:before, .search__detail_card:after {
  content: " ";
  display: table;
}
.search__detail_card:after {
  clear: both;
}
.search__detail_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.search__detail_footer:before, .search__detail_footer:after {
  content: " ";
  display: table;
}
.search__detail_footer:after {
  clear: both;
}
.search__detail_flight {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.search__detail_from, .search__detail_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.search__detail_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.search__detail .flight-details {
  float: left;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0;
}
.search__detail .flight-details__flexi-flight {
  padding-right: 0;
}
.search__detail .flexi-duration {
  padding-left: 0;
  padding-right: 0;
}
.search__detail_trip-title {
  padding-top: 15px;
  padding-bottom: 5px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
}
.search__detail:not(:first-child) .tripTitle {
  border-top: 2px solid #087dc2;
}
.search__detail .trip-title {
  padding-top: 0.625rem;
}
.search__detail .trip-title__from-city {
  color: #000;
  float: left;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  text-transform: capitalize;
}
.detail__flight, .detail__from, .detail__to, .detail__duration, .detail__price {
  padding-top: 0.9375rem;
}
.detail__fare-basis {
  color: #087dc2;
}
.detail__fare-rules, .detail__fare-basis {
  font-size: 0.625rem;
}
.detail__flight, .detail__duration {
  text-align: center;
}
.detail__from {
  text-align: left;
}
.detail__to {
  text-align: right;
}
.detail__from_airport, .detail__from_terminal, .detail__from_baggage, .detail__from_aircarft-type, .detail__to_airport, .detail__to_terminal, .detail__to_baggage, .detail__to_aircarft-type {
  font-size: 0.625rem;
  word-wrap: break-word;
}
.detail__from_airline, .detail__to_airline {
  font-size: 0.625rem;
}
.detail__from_location, .detail__to_location {
  text-transform: uppercase;
  color: #087dc2;
}
.detail__from_aircraft, .detail__to_aircraft {
  text-transform: uppercase;
  color: #087dc2;
}
.detail__from_time, .detail__from_date, .detail__to_time, .detail__to_date {
  font-family: "Open Sans";
}
.detail__from_date, .detail__to_date {
  font-size: 0.875rem;
  color: #838383;
}
.detail__from_time, .detail__to_time {
  font-size: 1.125rem;
  color: #087dc2;
  line-height: 1.125rem;
}
.detail__from_time .to-iata, .detail__from_time .from-iata, .detail__to_time .to-iata, .detail__to_time .from-iata {
  width: 100%;
}
.detail__from_suitcase, .detail__to_suitcase {
  color: #087dc2;
  font-size: 1.2rem;
}
.detail__fid {
  font-family: "Open Sans";
}
.detail__airline-xid {
  font-family: "Open Sans";
}
.detail__duration_flight-duration {
  font-size: 0.5625rem;
}
.detail__duration .flexi-time {
  font-size: 0.75rem;
  color: #55595c;
}
.detail__duration .flexi-time:before, .detail__duration .flexi-time:after {
  width: 1.0625rem;
  border: 0.0625rem #b6b6b6 dashed;
  background: #fff;
}
.detail__duration_time {
  font-size: 1.125rem;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.125rem;
  color: #f48083;
  position: relative;
  text-transform: lowercase;
}
.detail__duration_time:before, .detail__duration_time:after {
  width: 60px;
  content: "";
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  top: 50%;
}
.detail__duration_time:before {
  right: 100%;
}
.detail__duration_time:after {
  left: 100%;
}
.detail__duration_wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  padding-left: 0;
  padding-right: 0;
}
.detail__duration_wrapper:before, .detail__duration_wrapper:after {
  content: " ";
  display: table;
}
.detail__duration_wrapper:after {
  clear: both;
}
.detail__duration_duration-block {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.85rem;
}
.detail__seat_count_red {
  color: #ec2227;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.detail__seat_count_green {
  color: #908e8e;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.detail__seat_text {
  color: #087dc2;
  text-transform: capitalize;
}
.detail__flight-name:before, .detail__flight-name:after {
  content: " ";
  display: table;
}
.detail__flight-name:after {
  clear: both;
}
.detail__flight-name_checkbox {
  float: left;
  width: 10%;
}
.detail__flight-name_text {
  float: left;
  text-align: center;
  width: 90%;
}

.details__details_layover, .details__details_return {
  position: relative;
  text-align: center;
}
.details__details_layover:after, .details__details_return:after {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  content: "";
}
.details__details_layover-time .layover-clock {
  font-size: 1.25rem;
  color: #dd4b39;
  text-align: center;
}
.details__details_layover-time .leg-stop-location {
  font-size: 0.875rem;
  text-align: center;
}
.details__details_layover-time .layover-time {
  position: relative;
  text-align: center;
}
.details__details_layover-time .layover-time:after {
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  top: 0%;
  content: "";
  right: 0;
  border: 0.0625rem dashed #b6b6b6;
}
.details__details_return {
  color: #087dc2;
}
.details__details_layover:after {
  background-color: #b6b6b6;
}
.details__details_return:after {
  background-color: #c7e5f6;
}

.change__info {
  text-align: center;
  position: relative;
  z-index: 1;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  background-color: #fff;
}

.show__more {
  background-color: #f0f5f8;
  border-top: 1px solid #c7e5f6;
  margin-top: -15px;
  margin-bottom: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
}
.show__more a {
  color: #000;
}

.more__result {
  margin-top: -15px;
}

.fare-tab-data {
  padding: 0.5rem;
}

.fare-details, .flexi-fare-details {
  width: 100%;
  font-family: "Open Sans";
}
.fare-details__table, .flexi-fare-details__table {
  width: 100%;
}
.fare-details__table thead th, .flexi-fare-details__table thead th {
  color: #087dc2;
  text-transform: uppercase;
}
.fare-details__table tbody tr:last-child, .flexi-fare-details__table tbody tr:last-child {
  border-bottom: none;
  margin-left: -5px;
  margin-right: -5px;
}
.fare-details__table tr, .flexi-fare-details__table tr {
  border-bottom: 1px solid #c7e5f6;
}
.fare-details__table td, .flexi-fare-details__table td {
  text-align: right;
}
.fare-details__table th:last-child, .flexi-fare-details__table th:last-child {
  text-align: right;
}
.fare-details__table th,
.fare-details__table td, .flexi-fare-details__table th,
.flexi-fare-details__table td {
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-weight: 600;
  width: 10%;
}
.fare-details th, .fare-details td, .flexi-fare-details th, .flexi-fare-details td {
  text-align: right;
  width: 10%;
}
.fare-details th:first-child, .fare-details td:first-child, .flexi-fare-details th:first-child, .flexi-fare-details td:first-child {
  text-align: right;
  width: 10%;
}
.fare-details .pax-no, .flexi-fare-details .pax-no {
  text-align: center;
}
.fare-details .grand-total, .flexi-fare-details .grand-total {
  background-color: #f0f5f8;
  color: #000;
}
.fare-details .tax-breakup__button, .flexi-fare-details .tax-breakup__button {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  background-color: #ec2227;
  border: none;
  line-height: inherit;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-image: none;
  width: 100%;
  color: #fff;
  font-weight: 700;
  display: none;
}

.flexi-fare-details__table_fare-currency {
  float: left;
  padding-left: 0.8125rem;
}
.flexi-fare-details__table_fare-amount {
  float: right;
}
.flexi-fare-details__table table, .flexi-fare-details__table th, .flexi-fare-details__table td {
  width: 16%;
  padding-top: 0.125rem;
  padding-bottom: 0.1875rem;
}
.flexi-fare-details__table table {
  border-collapse: collapse;
}
.flexi-fare-details__adhoc-table table, .flexi-fare-details__adhoc-table th, .flexi-fare-details__adhoc-table td {
  width: 25%;
  padding-top: 0.125rem;
  padding-bottom: 0.1875rem;
}
.flexi-fare-details__adhoc-table tr {
  border-bottom: 1px solid #c7e5f6;
}
.flexi-fare-details__adhoc-footer {
  width: 100%;
  height: 1.8rem;
  background-color: #f0f5f8;
}

.baggage-details__header {
  width: 100%;
  display: table;
  border-bottom: 1px solid #c7e5f6;
  color: #087dc2;
}
.baggage-details__header_pax-type, .baggage-details__header_baggage-type {
  float: left;
  text-align: center;
  padding: 0.5rem;
  text-transform: uppercase;
}
.baggage-details__header_baggage-type {
  width: 40%;
}
.baggage-details__header_pax-type {
  width: 20%;
}
.baggage-details__row {
  width: 100%;
  display: table;
  border-bottom: 1px solid #c7e5f6;
}
.baggage-details__row_sector, .baggage-details__row_baggage-units {
  float: left;
  text-align: center;
  padding: 0.5rem;
}
.baggage-details__row_sector {
  width: 40%;
}
.baggage-details__row_baggage-units {
  width: 20%;
  text-transform: uppercase;
}
.baggage-details__row_baggage-info-icon {
  font-size: 1rem;
  color: #087dc2;
}
.baggage-details__message {
  padding: 1rem;
}

.flight__review_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.flight__review_main:before, .flight__review_main:after {
  content: " ";
  display: table;
}
.flight__review_main:after {
  clear: both;
}
.flight__review_main .content {
  padding-left: 0;
}
.flight__review_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .flight__review_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .flight__review_wrapper {
    max-width: 1400px;
  }
}
.flight__review_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.flight__review_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.flight__review_row:before, .flight__review_row:after {
  content: " ";
  display: table;
}
.flight__review_row:after {
  clear: both;
}
.flight__review_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.flight__review_card:before, .flight__review_card:after {
  content: " ";
  display: table;
}
.flight__review_card:after {
  clear: both;
}
.flight__review_card_header {
  padding: 0.375rem 0.9375rem;
}
.flight__review_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.flight__review_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.flight__review_header ul:before, .flight__review_header ul:after {
  content: " ";
  display: table;
}
.flight__review_header ul:after {
  clear: both;
}
.flight__review_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.flight__review_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.flight__review_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.flight__review_card_content {
  margin-bottom: 0.9375rem;
}
.flight__review_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.flight__review_footer:before, .flight__review_footer:after {
  content: " ";
  display: table;
}
.flight__review_footer:after {
  clear: both;
}
.flight__review_flight {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.flight__review_from, .flight__review_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.flight__review_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.flight__review_main {
  padding-top: 0.9375rem;
}
.flight__review_main .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.flight__review_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.flight__review_card .pnr-fare-selection__table_price-breakup .review-fare-details_card_header {
  margin-bottom: 3%;
}
.flight__review_card .pnr-fare-selection__trip_description {
  float: left;
}
.flight__review_card .pnr-fare-selection__select_btn {
  float: right;
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 18%;
  font-weight: 700;
  text-transform: uppercase;
}
.flight__review_card .pnr-fare-selection__select_btn:focus, .flight__review_card .pnr-fare-selection__select_btn.focus, .flight__review_card .pnr-fare-selection__select_btn:active:focus, .flight__review_card .pnr-fare-selection__select_btn:active.focus, .flight__review_card .pnr-fare-selection__select_btn.active:focus, .flight__review_card .pnr-fare-selection__select_btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.flight__review_card .pnr-fare-selection__select_btn:hover, .flight__review_card .pnr-fare-selection__select_btn:focus, .flight__review_card .pnr-fare-selection__select_btn.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.flight__review_card .pnr-fare-selection__select_btn:active, .flight__review_card .pnr-fare-selection__select_btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.flight__review_card .pnr-fare-selection__select_btn.disabled, .flight__review_card .pnr-fare-selection__select_btn[disabled], fieldset[disabled] .flight__review_card .pnr-fare-selection__select_btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.flight__review_card .pnr-fare-selection__fare-category-img-container {
  padding-right: 5px;
}
.flight__review_card .pnr-fare-selection__fare-category-img-container img {
  height: 20px;
}
.flight__review_card_header {
  text-transform: uppercase;
  color: #087dc2;
  padding-left: 0px;
}
.flight__review_card .header_text {
  margin-right: 0.234375rem;
  font-size: 0.9rem;
}
.flight__review_card .header_legs {
  position: relative;
}
.flight__review_card .header_legs:after {
  position: absolute;
  content: "";
  width: 30px;
  height: 1px;
  background-color: #087dc2;
  left: 100%;
  top: 50%;
  margin-top: -1px;
}
.flight__review_card .header_legs:last-child:after {
  content: none;
}
.flight__review_card .nav {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  background-color: #c7e5f6;
}
.flight__review_card .nav-tabs {
  border-bottom: none;
}
.flight__review_card .nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.flight__review_card .nav > li > a:hover,
.flight__review_card .nav > li > a:focus {
  background-color: #fff;
}
.flight__review_card .nav-tabs > li.active > a,
.flight__review_card .nav-tabs > li.active > a:hover,
.flight__review_card .nav-tabs > li.active > a:focus {
  color: #087dc2;
  background-color: #fff;
  border: none;
  cursor: default;
}

.review__flight, .review__from, .review__to, .review__duration, .review__price {
  padding-top: 0.9375rem;
}
.review__fare-basis {
  color: #087dc2;
}
.review__fare-rules, .review__fare-basis {
  font-size: 0.625rem;
}
.review__flight, .review__duration {
  text-align: center;
}
.review__from {
  text-align: left;
}
.review__to {
  text-align: right;
}
.review__from_airport, .review__from_terminal, .review__from_baggage, .review__from_aircarft-type, .review__to_airport, .review__to_terminal, .review__to_baggage, .review__to_aircarft-type {
  font-size: 0.625rem;
  word-wrap: break-word;
}
.review__from_airline, .review__to_airline {
  font-size: 0.625rem;
}
.review__from_location, .review__to_location {
  text-transform: uppercase;
  color: #087dc2;
}
.review__from_aircraft, .review__to_aircraft {
  text-transform: uppercase;
  color: #087dc2;
}
.review__from_time, .review__from_date, .review__to_time, .review__to_date {
  font-family: "Open Sans";
}
.review__from_date, .review__to_date {
  font-size: 0.875rem;
  color: #838383;
}
.review__from_time, .review__to_time {
  font-size: 1.125rem;
  color: #087dc2;
  line-height: 1.125rem;
}
.review__from_time .to-iata, .review__from_time .from-iata, .review__to_time .to-iata, .review__to_time .from-iata {
  width: 100%;
}
.review__from_suitcase, .review__to_suitcase {
  color: #087dc2;
  font-size: 1.2rem;
}
.review__fid {
  font-family: "Open Sans";
}
.review__airline-xid {
  font-family: "Open Sans";
}
.review__duration_flight-duration {
  font-size: 0.5625rem;
}
.review__duration .flexi-time {
  font-size: 0.75rem;
  color: #55595c;
}
.review__duration .flexi-time:before, .review__duration .flexi-time:after {
  width: 1.0625rem;
  border: 0.0625rem #b6b6b6 dashed;
  background: #fff;
}
.review__duration_time {
  font-size: 1.125rem;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 1.125rem;
  color: #f48083;
  position: relative;
  text-transform: lowercase;
}
.review__duration_time:before, .review__duration_time:after {
  width: 60px;
  content: "";
  height: 1px;
  background-color: #d0d0d0;
  position: absolute;
  top: 50%;
}
.review__duration_time:before {
  right: 100%;
}
.review__duration_time:after {
  left: 100%;
}
.review__duration_wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 80%;
  padding-left: 0;
  padding-right: 0;
}
.review__duration_wrapper:before, .review__duration_wrapper:after {
  content: " ";
  display: table;
}
.review__duration_wrapper:after {
  clear: both;
}
.review__duration_duration-block {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.85rem;
}
.review__seat_count_red {
  color: #ec2227;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.review__seat_count_green {
  color: #908e8e;
  font-size: 0.75rem;
  font-weight: bold;
  font-family: "Open Sans";
}
.review__seat_text {
  color: #087dc2;
  text-transform: capitalize;
}

.details__details_layover, .details__details_return {
  position: relative;
  text-align: center;
}
.details__details_layover:after, .details__details_return:after {
  position: absolute;
  width: 50%;
  height: 1px;
  top: 50%;
  margin-left: 25%;
  content: "";
}
.details__details_layover {
  font-family: "Open Sans";
}
.details__details_return {
  color: #087dc2;
}
.details__details_layover:after {
  background-color: #b6b6b6;
}
.details__details_return:after {
  background-color: #c7e5f6;
}

hr.legs-seperator {
  position: absolute;
  /* display: inline; */
  /* float: left; */
  width: 110px;
  height: 1px;
  background-color: red;
  top: 0;
  left: 50%;
  z-index: -1;
  margin-left: -55px;
}

.traveler-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.traveler-card__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.traveler-card__table {
  font-family: "Open Sans";
  width: 100%;
}
.traveler-card__table_total {
  background-color: #f0f5f8;
  color: #000;
}
.traveler-card__table tr td {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
}
.fare-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 0.9375rem;
  margin-bottom: 0.9375rem;
}
.fare-card__header {
  padding-bottom: 0.4375rem;
  border-bottom: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.fare-card__table {
  width: 100%;
  font-family: "Open Sans";
}
.fare-card__table_total {
  background-color: #f0f5f8;
  color: #000;
}
.fare-card__table tr td {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
}
.fare-card__table tr td:last-child {
  text-align: right;
}

.tax_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.tax_card:before, .tax_card:after {
  content: " ";
  display: table;
}
.tax_card:after {
  clear: both;
}
.tax_card_header {
  padding: 0.375rem 0.9375rem;
}
.tax_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.tax_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.tax_header ul:before, .tax_header ul:after {
  content: " ";
  display: table;
}
.tax_header ul:after {
  clear: both;
}
.tax_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.tax_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.tax_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.tax_card_content {
  margin-bottom: 0.9375rem;
}
.tax_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.tax_footer:before, .tax_footer:after {
  content: " ";
  display: table;
}
.tax_footer:after {
  clear: both;
}
.tax_flight {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.tax_from, .tax_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.tax_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.tax_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.tax_card_header {
  text-transform: uppercase;
  color: #087dc2;
}
.tax_card .header_text {
  margin-right: 1.875rem;
}
.tax__table {
  font-family: "Open Sans";
  width: 100%;
}
.tax__table_total {
  background-color: #f0f5f8;
  color: #000;
}
.tax__table tr td {
  border-bottom: 1px solid #b6b6b6;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
}
.tax__table tr td:last-child {
  text-align: right;
}

.tax-breakup_card_header {
  text-transform: uppercase;
  color: #087dc2;
}
.tax-breakup_card .header_text {
  margin-right: 1.875rem;
}
.tax-breakup__table {
  padding-left: 5px;
  padding-right: 5px;
}
.tax-breakup__table > table {
  width: 100%;
  font-family: "Open Sans";
}
.tax-breakup__table > table > thead {
  text-transform: uppercase;
  background-color: #f0f5f8;
}
.tax-breakup__table > table > thead th:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  text-align: left;
}
.tax-breakup__table > table > thead th {
  padding: 5px;
  text-align: right;
}
.tax-breakup__table > table > tbody {
  margin-top: 15px;
}
.tax-breakup__table > table > tbody > tr {
  border-bottom: 2px solid #f0f5f8;
}
.tax-breakup__table > table > tbody > tr td {
  padding: 5px;
  text-align: right;
  color: #087dc2;
  text-transform: none;
}
.tax-breakup__table > table > tbody > tr td:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  color: #838383;
}
.tax-breakup__table > table > tbody > tr:nth-child(2) td {
  text-transform: none;
}
.tax-breakup__table > table > tbody tr:last-child {
  background-color: #f0f5f8;
}
.tax-breakup__table > table > tbody tr:last-child td {
  padding: 5px;
  text-align: right;
  color: #000;
}
.tax-breakup__table > table > tbody tr:last-child td:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  text-transform: none;
}

.review-fare-details_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.review-fare-details_card:before, .review-fare-details_card:after {
  content: " ";
  display: table;
}
.review-fare-details_card:after {
  clear: both;
}
.review-fare-details_card_header {
  padding: 0.375rem 0.9375rem;
}
.review-fare-details_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.review-fare-details_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.review-fare-details_header ul:before, .review-fare-details_header ul:after {
  content: " ";
  display: table;
}
.review-fare-details_header ul:after {
  clear: both;
}
.review-fare-details_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.review-fare-details_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.review-fare-details_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.review-fare-details_card_content {
  margin-bottom: 0.9375rem;
}
.review-fare-details_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.review-fare-details_card_header {
  text-transform: uppercase;
  color: #087dc2;
  padding-left: 0px;
}
.review-fare-details_card .header_text {
  margin-right: 1.875rem;
}
.review-fare-details__table_price-breakup {
  float: left;
  text-transform: uppercase;
  padding: 0.375rem 0;
}
.review-fare-details__table_price-adhoc-amount {
  padding: 0.375rem 0;
  float: right;
}
.review-fare-details__table table {
  width: 100%;
  font-family: "Open Sans";
}
.review-fare-details__table table thead {
  text-transform: uppercase;
  background-color: #f0f5f8;
}
.review-fare-details__table table thead th:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  text-align: left;
}
.review-fare-details__table table thead th {
  padding: 5px;
  text-align: right;
}
.review-fare-details__table table tbody tr {
  border-bottom: 2px solid #f0f5f8;
}
.review-fare-details__table table tbody tr td {
  padding: 5px;
  text-align: right;
  color: #087dc2;
}
.review-fare-details__table table tbody tr td:first-child {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  color: #838383;
}

.total-amount,
.grand-total-amount {
  padding-left: 10px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  text-transform: uppercase;
}
.total-amount__value, .total-amount__result-page-value,
.grand-total-amount__value,
.grand-total-amount__result-page-value {
  float: right;
  color: #087dc2;
}
.total-amount__result-page-value,
.grand-total-amount__result-page-value {
  padding-right: 1.7rem;
  color: inherit;
}

.grand-total-amount {
  background-color: #f0f5f8;
  color: #000;
}
.grand-total-amount__value, .grand-total-amount__result-page-value {
  color: #000;
}

.modal__header {
  color: #087dc2;
  font-size: 0.875rem;
  padding-bottom: 10px;
}
.modal__header_heading {
  padding-left: 7px;
  float: left;
}
.modal__header_close {
  float: right;
  cursor: pointer;
}
.modal__header_info {
  margin-top: 7px;
  font-size: 1.125rem;
  background-color: #eaf5fc;
  height: 35px;
  padding: 5px 5px 5px 7px;
}

.heading__main:before, .heading__main:after {
  content: " ";
  display: table;
}
.heading__main:after {
  clear: both;
}

.header__info_part {
  color: #087dc2;
  float: left;
}

.header__info_part2 {
  color: #087dc2;
  float: right;
  font-size: 0.875rem;
  margin: 2px;
}

.header__info_subpart1 {
  padding-right: 10px;
}

.header__info_subpart2 {
  padding: 3px 20px 3px 20px;
  background-color: white;
}

.header__info_part1 {
  color: #f48083;
  margin-left: 5px;
  font-family: "Open Sans";
}

.modal__content {
  overflow-y: auto;
  margin-bottom: 30px;
  padding: 0;
  max-height: 550px;
  margin: 1.25rem 0;
}
.modal__content_fare-rule-loader {
  padding: 15px;
}
.modal__fare-rule-remark {
  color: #087dc2;
}

.modal-content {
  padding: 0.9375rem;
}
.modal-content:before, .modal-content:after {
  content: " ";
  display: table;
}
.modal-content:after {
  clear: both;
}

.panel-group {
  padding-right: 0px;
}

.panel {
  margin-bottom: 6px;
}

.panel__heading {
  color: #000;
  background-color: #eaf5fc;
  font-size: 0.75rem;
  font-weight-base: 600;
  padding: 5px 5px 5px 7px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}
.panel__heading:before, .panel__heading:after {
  content: " ";
  display: table;
}
.panel__heading:after {
  clear: both;
}
.panel__heading .triangle__container {
  border-radius: 100%;
  width: 18px;
  height: 18px;
  background-color: black;
  position: relative;
  float: right;
}
.panel__heading .triangle {
  height: 0;
  width: 0;
  border-color: white;
  border: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  top: 7px;
  left: 4px;
}
.panel__heading.collapsed {
  color: #838383;
}
.panel__heading.collapsed .triangle {
  height: 0;
  width: 0;
  border-color: white;
  border: 5px solid transparent;
  border-left: 5px solid #fff;
  top: 4px;
  left: 7px;
}
.panel__heading.collapsed .triangle__container {
  background-color: #9d9d9d;
}
.panel__heading_highlight {
  background-color: #c7e5f6;
}
.panel__heading_highlight .triangle__container {
  background-color: #3b5998;
}
.panel__heading_highlight.collapsed .triangle__container {
  background-color: #3b5998;
}

.panel__body {
  padding: 7px 0 3px 7px;
  border-left: 1px solid #EEEFF3;
  border-right: 1px solid #EEEFF3;
  border-bottom: 1px solid #EEEFF3;
}

.panel__heading_info {
  float: left;
  color: #373a3c;
}

.rule-header__sub-title_column-small, .rule-header__sub-title_column-medium, .rule-header__sub-title_column-large, .rule-header__title {
  padding: 0.3125rem;
  font-weight: bold;
  font-size: 0.75rem;
  text-transform: uppercase;
  background-color: #087dc2;
  color: #fff;
  box-sizing: border-box;
  float: left;
}
.rule-header__sub-title_column-small:not(:last-child), .rule-header__sub-title_column-medium:not(:last-child), .rule-header__sub-title_column-large:not(:last-child), .rule-header__title:not(:last-child) {
  border-right: 2px solid;
}

.rule-table__row_rule-title, .rule-table__row_rule-sub-row, .rule-table__row_rule-sub-header, .rule-table__row_rule-header, .rule-table__row_rule-text {
  padding: 0.3125rem;
  font-size: 0.75rem;
}

.rule-table__row_rule-sub-row, .rule-table__row_rule-sub-header, .rule-table__row_rule-header {
  font-weight: bold;
  background-color: #fff;
  float: left;
  color: #373a3c;
  font-weight: 500;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}
.rule-table__row_rule-sub-row:not(:last-child), .rule-table__row_rule-sub-header:not(:last-child), .rule-table__row_rule-header:not(:last-child) {
  border-right: 3px solid #f0f5f8;
}

.fare-rule-table {
  width: 100%;
  text-align: left;
}

.rule-header__row {
  width: 100%;
  margin-bottom: 0.625rem;
}
.rule-header__row:before, .rule-header__row:after {
  content: " ";
  display: table;
}
.rule-header__row:after {
  clear: both;
}
.rule-header__title {
  width: 40%;
}
.rule-header__sub-title_column-large {
  width: 60%;
}
.rule-header__sub-title_column-medium {
  width: 30%;
}
.rule-header__sub-title_column-small {
  width: 20%;
}

.rule-table {
  background-color: #f0f5f8;
  margin-bottom: 0.3125rem;
  border-radius: 0.625rem 0.625rem 0 0;
  word-wrap: break-word;
}
.rule-table__row {
  width: 100%;
  text-transform: uppercase;
  padding: 0 0.3125rem 0.3125rem 0.3125rem;
  display: flex;
}
.rule-table__row_rule-text {
  width: auto;
  color: #55595c;
  background-color: #eaf5fc;
}
.rule-table__row_rule-text sup {
  color: #ec2227;
}
.rule-table__row_rule-header-large {
  width: 60%;
}
.rule-table__row_rule-header-medium {
  width: 30%;
}
.rule-table__row_rule-header-small {
  width: 20%;
}
.rule-table__row_rule-sub-header {
  box-sizing: border-box;
  width: 40%;
}
.rule-table__row_rule-sub-row {
  box-sizing: border-box;
  width: 100%;
}
.rule-table__row_rule-sub-row_link {
  color: #f48083;
  text-transform: uppercase;
  font-weight: bold;
}
.rule-table__row_rule-title {
  font-weight: bold;
  color: #087dc2;
}
.rule-table__sub_row {
  text-transform: uppercase;
}

.mini-fare-rule {
  width: 100%;
}
.mini-fare-rule__info {
  width: 100%;
}
.mini-fare-rule__info_text {
  color: #087dc2;
  font-family: "Open Sans";
  font-size: 12px;
}
.mini-fare-rule__info_icon {
  color: #ec2227;
  font-size: 0.875rem;
}

.flight__pax-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.flight__pax-info_main:before, .flight__pax-info_main:after {
  content: " ";
  display: table;
}
.flight__pax-info_main:after {
  clear: both;
}
.flight__pax-info_main .content {
  padding-left: 0;
}
.flight__pax-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .flight__pax-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .flight__pax-info_wrapper {
    max-width: 1400px;
  }
}
.flight__pax-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.flight__pax-info_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.flight__pax-info_card:before, .flight__pax-info_card:after {
  content: " ";
  display: table;
}
.flight__pax-info_card:after {
  clear: both;
}
.flight__pax-info_card_header {
  padding: 0.375rem 0.9375rem;
}
.flight__pax-info_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.flight__pax-info_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.flight__pax-info_header ul:before, .flight__pax-info_header ul:after {
  content: " ";
  display: table;
}
.flight__pax-info_header ul:after {
  clear: both;
}
.flight__pax-info_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.flight__pax-info_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.flight__pax-info_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.flight__pax-info_card_content {
  margin-bottom: 0.9375rem;
}
.flight__pax-info_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding-bottom: 0.9375rem;
}
.flight__pax-info_card_extra {
  border-bottom: 1px solid #087dc2;
  padding-bottom: 0.9375rem;
}
.flight__pax-info_card_header {
  text-transform: uppercase;
  color: #087dc2;
  padding-left: 0px;
}
.flight__pax-info_card_info {
  color: #ec2227;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-left: 0.2rem;
}
.flight__pax-info_on-hold-notification {
  display: flex;
  justify-content: flex-end;
  color: #ec2227;
}
.flight__pax-info_preferences .preferences__heading {
  text-transform: uppercase;
  color: #087dc2;
  margin-bottom: 15px;
  width: 25%;
}
.flight__pax-info_preferences .preferences__heading:hover {
  cursor: pointer;
  text-decoration: underline;
}
.flight__pax-info_preferences .preferences__container {
  padding-left: 200px;
}
.flight__pax-info_preferences .preferences__container:before, .flight__pax-info_preferences .preferences__container:after {
  content: " ";
  display: table;
}
.flight__pax-info_preferences .preferences__container:after {
  clear: both;
}
.flight__pax-info_preferences .preferences__container .seat-preference, .flight__pax-info_preferences .preferences__container .meal-preference, .flight__pax-info_preferences .preferences__container .ffn-preference {
  padding: 7px 0px;
}
.flight__pax-info_preferences .preferences__container .seat-preference:before, .flight__pax-info_preferences .preferences__container .seat-preference:after, .flight__pax-info_preferences .preferences__container .meal-preference:before, .flight__pax-info_preferences .preferences__container .meal-preference:after, .flight__pax-info_preferences .preferences__container .ffn-preference:before, .flight__pax-info_preferences .preferences__container .ffn-preference:after {
  content: " ";
  display: table;
}
.flight__pax-info_preferences .preferences__container .seat-preference:after, .flight__pax-info_preferences .preferences__container .meal-preference:after, .flight__pax-info_preferences .preferences__container .ffn-preference:after {
  clear: both;
}
.flight__pax-info_preferences .preferences__container .seat-preference__heading, .flight__pax-info_preferences .preferences__container .seat-preference__options, .flight__pax-info_preferences .preferences__container .meal-preference__heading, .flight__pax-info_preferences .preferences__container .meal-preference__options, .flight__pax-info_preferences .preferences__container .ffn-preference__heading, .flight__pax-info_preferences .preferences__container .ffn-preference__options {
  float: left;
}
.flight__pax-info_preferences .preferences__container .seat-preference__heading, .flight__pax-info_preferences .preferences__container .meal-preference__heading, .flight__pax-info_preferences .preferences__container .ffn-preference__heading {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
  width: 20%;
  text-align: right;
}
.flight__pax-info_preferences .preferences__container .seat-preference__options, .flight__pax-info_preferences .preferences__container .meal-preference__options, .flight__pax-info_preferences .preferences__container .ffn-preference__options {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 60%;
}
.flight__pax-info_preferences .preferences__container .seat-preference__options > select, .flight__pax-info_preferences .preferences__container .meal-preference__options > select, .flight__pax-info_preferences .preferences__container .ffn-preference__options > select {
  width: 45%;
  height: 23px;
}
.flight__pax-info_preferences .preferences__container .ffn-preference__options_container:before, .flight__pax-info_preferences .preferences__container .ffn-preference__options_container:after {
  content: " ";
  display: table;
}
.flight__pax-info_preferences .preferences__container .ffn-preference__options_container:after {
  clear: both;
}
.flight__pax-info_preferences .preferences__container .ffn-preference__options_container .ffn-options, .flight__pax-info_preferences .preferences__container .ffn-preference__options_container .ffn-number {
  float: left;
  width: 30%;
  margin-right: 15px;
  padding: 7px 0px;
}
.flight__pax-info_preferences .preferences__container .ffn-preference__options_container .ffn-options > select, .flight__pax-info_preferences .preferences__container .ffn-preference__options_container .ffn-number > select {
  width: 100%;
  height: 23px;
}
.flight__pax-info_row {
  border-top: 1px solid #087dc2;
}
.flight__pax-info_row:before, .flight__pax-info_row:after {
  content: " ";
  display: table;
}
.flight__pax-info_row:after {
  clear: both;
}
.flight__pax-info_row:last-child {
  border-bottom: 1px solid #087dc2;
}
.flight__pax-info_footer {
  padding-top: 0.9375rem;
}
.flight__pax-info_footer:before, .flight__pax-info_footer:after {
  content: " ";
  display: table;
}
.flight__pax-info_footer:after {
  clear: both;
}
.flight__pax-info .footer_text {
  margin-bottom: 0;
}
.flight__pax-info_button, .flight__pax-info_add-dubz-button {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  background-color: #ec2227;
  border: none;
  line-height: inherit;
  border-radius: 0;
  background-image: none;
  width: 25%;
  color: #fff;
  font-weight: 700;
  float: right;
  margin-left: 0.625rem;
}
.flight__pax-info_button:hover, .flight__pax-info_add-dubz-button:hover {
  background-color: #9b0d11;
}
.flight__pax-info_button:disabled, .flight__pax-info_add-dubz-button:disabled {
  cursor: not-allowed;
  opacity: 0.65;
  box-shadow: none;
}
.flight__pax-info_save-traveler-btn {
  float: right;
  width: 25%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #fff;
  background-color: #087dc2;
  font-weight: 700;
}
.flight__pax-info_save-traveler-btn:focus, .flight__pax-info_save-traveler-btn.focus, .flight__pax-info_save-traveler-btn:active:focus, .flight__pax-info_save-traveler-btn:active.focus, .flight__pax-info_save-traveler-btn.active:focus, .flight__pax-info_save-traveler-btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.flight__pax-info_save-traveler-btn:hover, .flight__pax-info_save-traveler-btn:focus, .flight__pax-info_save-traveler-btn.focus {
  color: #fff;
  text-decoration: none;
  background-color: #043e60;
}
.flight__pax-info_save-traveler-btn:active, .flight__pax-info_save-traveler-btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.flight__pax-info_save-traveler-btn.disabled, .flight__pax-info_save-traveler-btn[disabled], fieldset[disabled] .flight__pax-info_save-traveler-btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.flight__pax-info_ancillary-button-block {
  width: 73%;
  padding-left: 0.85rem;
  padding-right: 0.85rem;
  float: left;
  padding-top: 0.9375rem;
}
.flight__pax-info_ancillary-button-block > button {
  width: 35%;
}

.hotel__pax-info_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.hotel__pax-info_main:before, .hotel__pax-info_main:after {
  content: " ";
  display: table;
}
.hotel__pax-info_main:after {
  clear: both;
}
.hotel__pax-info_main .content {
  padding-left: 0;
}
.hotel__pax-info_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .hotel__pax-info_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .hotel__pax-info_wrapper {
    max-width: 1400px;
  }
}
.hotel__pax-info_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.hotel__pax-info_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.hotel__pax-info_card:before, .hotel__pax-info_card:after {
  content: " ";
  display: table;
}
.hotel__pax-info_card:after {
  clear: both;
}
.hotel__pax-info_card_header {
  padding: 0.375rem 0.9375rem;
}
.hotel__pax-info_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.hotel__pax-info_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.hotel__pax-info_header ul:before, .hotel__pax-info_header ul:after {
  content: " ";
  display: table;
}
.hotel__pax-info_header ul:after {
  clear: both;
}
.hotel__pax-info_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.hotel__pax-info_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.hotel__pax-info_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.hotel__pax-info_card_content {
  margin-bottom: 0.9375rem;
}
.hotel__pax-info_card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}
.hotel__pax-info_card_header {
  text-transform: uppercase;
  color: #087dc2;
}
.hotel__pax-info_card_info {
  color: #ec2227;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding-left: 0.2rem;
}
.hotel__pax-info_row:before, .hotel__pax-info_row:after {
  content: " ";
  display: table;
}
.hotel__pax-info_row:after {
  clear: both;
}
.hotel__pax-info_header ul > li {
  float: left;
  width: 20%;
  text-align: center;
  font-size: 0.625rem;
}
.hotel__pax-info_header ul > li:nth-child(1), .hotel__pax-info_header ul > li:nth-child(2) {
  width: 10%;
  text-align: center;
}
.hotel__pax-info_header ul > li:last-child {
  width: 20%;
  text-align: center;
}
.hotel__pax-info_footer {
  border-top: 1px solid #087dc2;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.hotel__pax-info_footer:before, .hotel__pax-info_footer:after {
  content: " ";
  display: table;
}
.hotel__pax-info_footer:after {
  clear: both;
}
.hotel__pax-info .footer_text {
  margin-bottom: 0;
}
.hotel__pax-info_room {
  margin-bottom: 0.9375rem;
  border-top: 1px solid #087dc2;
}
.hotel__pax-info_room_text {
  text-transform: uppercase;
  color: #087dc2;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}
.hotel__pax-info_room .pax-info {
  padding-bottom: 0;
}
.hotel__pax-info_button {
  padding: 0.3125rem 0;
  font-size: 0.875rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  border: none;
  width: 25%;
  font-weight: 700;
  float: right;
}
.hotel__pax-info_button:focus, .hotel__pax-info_button.focus, .hotel__pax-info_button:active:focus, .hotel__pax-info_button:active.focus, .hotel__pax-info_button.active:focus, .hotel__pax-info_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.hotel__pax-info_button:hover, .hotel__pax-info_button:focus, .hotel__pax-info_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.hotel__pax-info_button:active, .hotel__pax-info_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.hotel__pax-info_button.disabled, .hotel__pax-info_button[disabled], fieldset[disabled] .hotel__pax-info_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hotel__pax-info_save-traveler {
  width: 100%;
}
.hotel__pax-info_save-traveler:before, .hotel__pax-info_save-traveler:after {
  content: " ";
  display: table;
}
.hotel__pax-info_save-traveler:after {
  clear: both;
}
.hotel__pax-info_save-traveler_button {
  float: right;
  width: 25%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  color: #fff;
  background-color: #087dc2;
  font-weight: 700;
  margin-bottom: 0.625rem;
}
.hotel__pax-info_save-traveler_button:focus, .hotel__pax-info_save-traveler_button.focus, .hotel__pax-info_save-traveler_button:active:focus, .hotel__pax-info_save-traveler_button:active.focus, .hotel__pax-info_save-traveler_button.active:focus, .hotel__pax-info_save-traveler_button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.hotel__pax-info_save-traveler_button:hover, .hotel__pax-info_save-traveler_button:focus, .hotel__pax-info_save-traveler_button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #043e60;
}
.hotel__pax-info_save-traveler_button:active, .hotel__pax-info_save-traveler_button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.hotel__pax-info_save-traveler_button.disabled, .hotel__pax-info_save-traveler_button[disabled], fieldset[disabled] .hotel__pax-info_save-traveler_button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.view__cart {
  float: right;
  padding: 0.3125rem;
}

.hotel__pax-info_main .content {
  padding-left: 0.9375rem;
}

.asterisk {
  color: #ec2227;
  font-size: 0.375rem;
  vertical-align: text-top;
}

.air__ancillary_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__ancillary_main:before, .air__ancillary_main:after {
  content: " ";
  display: table;
}
.air__ancillary_main:after {
  clear: both;
}
.air__ancillary_main .content {
  padding-left: 0;
}
.air__ancillary_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__ancillary_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__ancillary_wrapper {
    max-width: 1400px;
  }
}
.air__ancillary_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__ancillary_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.air__ancillary_row:before, .air__ancillary_row:after {
  content: " ";
  display: table;
}
.air__ancillary_row:after {
  clear: both;
}
.air__ancillary_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.air__ancillary_card:before, .air__ancillary_card:after {
  content: " ";
  display: table;
}
.air__ancillary_card:after {
  clear: both;
}
.air__ancillary_card_header {
  padding: 0.375rem 0.9375rem;
}
.air__ancillary_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.air__ancillary_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.air__ancillary_header ul:before, .air__ancillary_header ul:after {
  content: " ";
  display: table;
}
.air__ancillary_header ul:after {
  clear: both;
}
.air__ancillary_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.air__ancillary_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.air__ancillary_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.air__ancillary_card_content {
  margin-bottom: 0.9375rem;
}
.air__ancillary_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.air__ancillary_footer:before, .air__ancillary_footer:after {
  content: " ";
  display: table;
}
.air__ancillary_footer:after {
  clear: both;
}
.air__ancillary_flight {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.air__ancillary_from, .air__ancillary_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.air__ancillary_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.air__ancillary_wrapper {
  padding-top: 0.9375rem;
}
.air__ancillary_wrapper:before, .air__ancillary_wrapper:after {
  content: " ";
  display: table;
}
.air__ancillary_wrapper:after {
  clear: both;
}
.air__ancillary_wrapper .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.air__ancillary_wrapper .ancillary-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
}
.air__ancillary_wrapper .ancillary-content__meal_wrapper, .air__ancillary_wrapper .ancillary-content__baggage_wrapper {
  width: 100%;
}
.air__ancillary_wrapper .ancillary-content__price_wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.air__ancillary_wrapper .ancillary-content__price_wrapper .sidebar {
  width: 100%;
  padding-top: 0.9375rem;
}
.air__ancillary_wrapper .ancillary-content__price_wrapper .sidebar .book-card__table {
  width: 100%;
}
.air__ancillary_footer {
  padding-left: 30px;
  margin-bottom: 30px;
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
}
.air__ancillary_footer .add-ancillary-button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-left: 30px;
  margin-top: 30px;
  background-color: #ec2227;
  border: none;
  line-height: inherit;
  border-radius: 0;
  background-image: none;
  width: 25%;
  color: #fff;
  font-weight: 700;
  float: right;
}
.air__ancillary_footer .add-ancillary-button:hover {
  background-color: #9b0d11;
}

.pnr-result__header {
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid #b6b6b6;
}
.pnr-result__header:before, .pnr-result__header:after {
  content: " ";
  display: table;
}
.pnr-result__header:after {
  clear: both;
}
.pnr-result__header_status {
  float: left;
  text-transform: uppercase;
}
.pnr-result__header_status-confirmed {
  color: green;
}
.pnr-result__header_status-waiting {
  color: #FFBF00;
}
.pnr-result__header_status-cancelled {
  color: red;
}
.pnr-result__header_ticket-number {
  color: #838383;
  font-family: "Open Sans";
}
.pnr-result__header_additional-info-container {
  float: right;
  position: relative;
  text-transform: none;
}
.pnr-result__header_additional-info-link {
  cursor: pointer;
  color: #087dc2;
}
.pnr-result__header_additional-info-link:hover {
  text-decoration: none;
}
.pnr-result__header_additional-info {
  width: 120px;
  padding: 2px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.pnr-result__header_additional-info .popover-content {
  padding: 2px;
}
.pnr-result__footer {
  border-top: 1px solid #b6b6b6;
  color: #087dc2;
  font-weight: 600;
  min-height: 1.875rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
  text-align: right;
}
.pnr-result__footer_checkbox .checkbox__input-container {
  vertical-align: text-top;
}

.pnr-modal__content {
  margin-top: 70px;
  padding-left: 0.9375rem;
  width: 99%;
}

.pnr-flight-details {
  padding-left: 0;
  padding-right: 0;
}
.pnr-flight-details .search__detail_row {
  margin: 0;
  padding: 0.5rem 0;
}
.pnr-flight-details .detail__flight {
  padding-top: 3px;
}
.pnr-flight-details__ticketed {
  background: #e6ffe6;
}
.pnr-flight-details__cancelled {
  background: #f0f5f8;
  color: #838383;
}

.pnr-flight-details {
  padding-top: 0.234375rem;
  padding-bottom: 0.234375rem;
}

.pnr__result_select-ticketing-airline {
  float: left;
}
.pnr__result_select-fare-type {
  float: left;
  padding-left: 2rem;
}
.pnr__result_book {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  padding-left: 0.625rem;
  padding-right: 0px;
  float: right;
}
.pnr__result_book-button {
  padding: 0.3125rem 0;
  font-size: 0.625rem;
  line-height: inherit;
  border-radius: 0;
  color: #fff;
  background-color: #ec2227;
  width: 100%;
  font-weight: 700;
  text-transform: uppercase;
}
.pnr__result_book-button:focus, .pnr__result_book-button.focus, .pnr__result_book-button:active:focus, .pnr__result_book-button:active.focus, .pnr__result_book-button.active:focus, .pnr__result_book-button.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.pnr__result_book-button:hover, .pnr__result_book-button:focus, .pnr__result_book-button.focus {
  color: #fff;
  text-decoration: none;
  background-color: #9b0d11;
}
.pnr__result_book-button:active, .pnr__result_book-button.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.pnr__result_book-button.disabled, .pnr__result_book-button[disabled], fieldset[disabled] .pnr__result_book-button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.air-select-airline {
  padding: 0.2rem 1rem;
  margin-left: 0.5rem;
}

.pnr-import-modal {
  text-align: center;
}
.pnr-import-modal__text {
  color: #087dc2;
  padding-bottom: 0.625rem;
  font-size: 18px;
}
.pnr-import-modal__button {
  width: 4.5rem;
  padding: 0.375rem;
  background-color: #c7e5f6;
  border: none;
  font-size: 1rem;
  color: #087dc2;
  text-transform: uppercase;
  cursor: pointer;
}

.modal-open .pnr-import-modal.modal {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 40%;
  margin-left: 32%;
  margin-top: 10%;
}

.pnr-search-results__header {
  background-color: #c7e5f6;
  display: inline-block;
  padding: 0.375rem;
  color: #087dc2;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.pnr-search-results__alternative-search-result {
  background-color: #f0f5f8;
  color: #000;
  cursor: pointer;
}
.pnr-search-results__alternative-result-error {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.pnr-search-results__alternative-result-error .nav {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  background-color: #c7e5f6;
}
.pnr-search-results__alternative-result-error .nav-tabs {
  border-bottom: none;
}
.pnr-search-results__alternative-result-error .nav-tabs > li > a {
  margin-right: 0;
  border: none;
  border-radius: 0;
  color: #087dc2;
  font-size: 0.875rem;
}
.pnr-search-results__alternative-result-error .nav > li > a:hover,
.pnr-search-results__alternative-result-error .nav > li > a:focus {
  background-color: #fff;
}
.pnr-search-results__alternative-result-error .nav-tabs > li.active > a,
.pnr-search-results__alternative-result-error .nav-tabs > li.active > a:hover,
.pnr-search-results__alternative-result-error .nav-tabs > li.active > a:focus {
  color: #087dc2;
  background-color: #fff;
  border: none;
  cursor: default;
}

.form-data {
  padding-bottom: 2.5rem;
}

.pnr-fare-selection__container_error {
  text-align: center;
}
.pnr-fare-selection__container_error .error__message {
  padding: 0.5rem 1rem;
}

.fare-rule-container .nav-tabs {
  border-bottom: 0;
}
.fare-rule-container .nav-tabs li > a {
  color: #087dc2;
  background-color: #c7e5f6;
  border: 0;
  border-radius: 0;
  font-size: 0.75rem;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}
.fare-rule-container .nav-tabs li.active > a {
  color: #fff;
  background-color: #087dc2;
  border: 0;
  border-radius: 0;
  font-size: 0.75rem;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}
.fare-rule-container .link {
  color: #ec2227;
  text-decoration: none;
}

.fare-family {
  width: 100%;
  position: relative;
}
.fare-family tr {
  border-bottom: none;
}
.fare-family th, .fare-family td {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  width: auto;
  line-height: 0.9rem;
}
.fare-family table {
  table-layout: fixed;
}
.fare-family__header {
  height: 3rem;
  padding: 0.5rem;
  border: 1px solid #c7e5f6;
  display: block;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #c7e5f6;
  text-transform: none;
  display: inline-flex;
  min-width: 12rem;
}
.fare-family__header_fare-class {
  display: inline-block;
  width: 100%;
  color: #087dc2;
  text-align: center;
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 10rem;
  overflow: hidden;
  padding: 0 0.8rem 0 0.8rem;
}
.fare-family__header_fare-type {
  display: inline-block;
  text-align: center;
  font-size: 0.8rem;
}
.fare-family__header_amount, .fare-family__header_amount-with-adhoc {
  display: inline-block;
  width: 45%;
  font-size: 0.65rem;
  float: right;
  font-weight: 700;
}
.fare-family__header_amount-with-adhoc {
  color: #0000FF;
}
.fare-family__header_flight-pref-img img {
  width: 0.9375rem;
  padding-bottom: 0.5rem;
}
.fare-family__header_flexi-info-icon {
  color: #000;
}
.fare-family__side-header {
  display: block;
  width: 2.5rem;
  color: #55595c;
  position: absolute;
  top: 4.6rem;
  left: 0.1rem;
}
.fare-family__side-header td, .fare-family__side-header th {
  display: block;
  width: 2.5rem;
}
.fare-family__side-header_content {
  text-align: left;
  position: absolute;
  left: 5px;
}
.fare-family__table {
  width: 100%;
}
.fare-family__table td {
  text-align: center;
  padding: 0;
  min-height: 3rem;
  height: 3rem;
  position: relative;
}
.fare-family__table tr {
  border: none;
  height: 3rem;
}
.fare-family__table_link {
  display: block;
  width: 100%;
  text-align: center;
}
.fare-family__table_content, .fare-family__table_link {
  display: block;
  width: 100%;
  position: relative;
}
.fare-family__table_content a, .fare-family__table_link a {
  width: 100%;
  display: block;
}
.fare-family__table_content .refundable-status, .fare-family__table_link .refundable-status {
  text-transform: uppercase;
}
.fare-family__table_content .cabin-class, .fare-family__table_link .cabin-class {
  color: #087dc2;
}
.fare-family__table_link {
  line-height: 1.5rem;
}
.fare-family__return-trip-header {
  display: block;
  margin: 0.2rem;
  width: 98%;
  text-align: center;
  font-size: 0.7rem;
}
.fare-family__return-trip-header_going-trip, .fare-family__return-trip-header_coming-trip {
  padding: 0.2rem;
  border: 1px solid;
  width: 7.5rem;
  display: inline-block;
  height: 2.5rem;
  overflow: hidden;
  background: #c7e5f6;
  color: #087dc2;
}
.fare-family__services {
  color: #087dc2;
  display: block;
  line-height: 0.9rem;
  word-break: break-word;
}
.fare-family__flight-services {
  vertical-align: top;
}
.fare-family__flexi-flight-services {
  display: inline-block;
  padding: 0.5rem;
  width: 100%;
  text-align: center;
}
.fare-family__flexi-info-icon {
  font-size: 0.9rem;
  color: #087dc2;
  padding-left: 0.5rem;
}
.fare-family__air-port-message-info-icons {
  font-size: 1rem;
  color: #ec2227;
}
.fare-family__scroll-button_prev-btn, .fare-family__scroll-button_next-btn {
  width: 0.9rem;
  position: absolute;
  font-size: 1.3rem;
  cursor: pointer;
  z-index: 1;
  margin-top: 1.65rem;
  color: #087dc2;
}
.fare-family__scroll-button_prev-btn {
  float: left;
  left: 0.325rem;
}
.fare-family__scroll-button_next-btn {
  float: right;
  right: 0.325rem;
}
.fare-family__more-info {
  padding: 0.5rem;
}
.fare-family__more-info td {
  height: auto;
}
.fare-family__more-info_button {
  padding: 0.5rem;
}
.fare-family__more-info_button .more-info-button {
  border: 1px solid #087dc2;
  padding: 0.2rem 0.5rem;
  border-radius: 0.3rem;
  color: #087dc2;
  cursor: pointer;
}
.fare-family__more-info_button .more-info-button__plus, .fare-family__more-info_button .more-info-button__minus {
  padding-left: 0.2rem;
  font-size: 0.625rem;
}
.fare-family__more-info_flight-services {
  display: block;
  padding: 0.5rem;
  height: auto;
}
.fare-family__flight-services-left-header {
  width: 9rem;
  display: block;
  color: #087dc2;
}
.fare-family__fetch-fare-loader {
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.fare-family__fetch-fare-loader_content {
  color: #087dc2;
  text-align: center;
  padding: 0.9375rem;
}

.fare-family-link {
  color: #087dc2 !important;
}

.active-fare-family-link {
  color: #ec2227 !important;
}

.fare-type-display, .reissue-error-message {
  color: #ec2227;
}

.more-fare {
  display: flex;
  height: 100%;
  position: relative;
}
.more-fare__multi-trip-fare-list, .more-fare__multi-trip-fare-list-on-view-details {
  display: block;
  float: left;
  width: 65%;
  height: 100%;
}
.more-fare__multi-trip-fare-list tr, .more-fare__multi-trip-fare-list-on-view-details tr {
  border-bottom: 1px solid #c7e5f6;
}
.more-fare__multi-trip-fare-list-on-view-details {
  width: 100%;
  border-right: none;
}
.more-fare__multi-trip-fare-details {
  display: block;
  float: right;
  width: 35%;
  padding-left: 0.5rem;
}
.more-fare__separator {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 10px solid #c7e5f6;
  right: 34%;
}
.more-fare__side-header {
  display: block;
  width: 100%;
  text-align: left;
}
.more-fare__side-header_label {
  display: inline-block;
  width: 47%;
  color: #087dc2;
  padding-left: 0.5rem;
}
.more-fare__side-header_icon {
  width: 50%;
  display: inline-block;
}

.flight-service-tooltip {
  width: auto;
  min-width: 120px;
}

.ancillary-select_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.ancillary-select_main:before, .ancillary-select_main:after {
  content: " ";
  display: table;
}
.ancillary-select_main:after {
  clear: both;
}
.ancillary-select_main .content {
  padding-left: 0;
}
.ancillary-select_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .ancillary-select_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .ancillary-select_wrapper {
    max-width: 1400px;
  }
}
.ancillary-select_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.ancillary-select_main {
  padding-top: 0.9375rem;
}
.ancillary-select_main .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.ancillary-select .ancillary-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
}
.ancillary-select .ancillary-content__meal_wrapper, .ancillary-select .ancillary-content__baggage_wrapper, .ancillary-select .ancillary-content__seat_wrapper {
  width: 100%;
}
.ancillary-select .ancillary-content__price_wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.ancillary-select .ancillary-content__price_wrapper .sidebar {
  width: 100%;
  padding-top: 0.9375rem;
}
.ancillary-select .ancillary-content__price_wrapper .sidebar .book-card__table {
  width: 100%;
}

.ancillary-seat-selector {
  padding-top: 0.9375rem;
}
.ancillary-seat-selector_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.ancillary-seat-selector_main:before, .ancillary-seat-selector_main:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector_main:after {
  clear: both;
}
.ancillary-seat-selector_main .content {
  padding-left: 0;
}
.ancillary-seat-selector_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .ancillary-seat-selector_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .ancillary-seat-selector_wrapper {
    max-width: 1400px;
  }
}
.ancillary-seat-selector_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.ancillary-seat-selector_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.ancillary-seat-selector_row:before, .ancillary-seat-selector_row:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector_row:after {
  clear: both;
}
.ancillary-seat-selector_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.ancillary-seat-selector_card:before, .ancillary-seat-selector_card:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector_card:after {
  clear: both;
}
.ancillary-seat-selector_card_header {
  padding: 0.375rem 0.9375rem;
}
.ancillary-seat-selector_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.ancillary-seat-selector_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.ancillary-seat-selector_header ul:before, .ancillary-seat-selector_header ul:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector_header ul:after {
  clear: both;
}
.ancillary-seat-selector_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.ancillary-seat-selector_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.ancillary-seat-selector_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.ancillary-seat-selector_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.ancillary-seat-selector_footer:before, .ancillary-seat-selector_footer:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector_footer:after {
  clear: both;
}
.ancillary-seat-selector:before, .ancillary-seat-selector:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector:after {
  clear: both;
}
.ancillary-seat-selector__content {
  padding-right: 0;
  width: 100%;
}
.ancillary-seat-selector__card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.ancillary-seat-selector__card_header {
  text-transform: uppercase;
  color: #087dc2;
  background: #fff;
  padding-left: 0.3125rem;
  width: 100%;
  height: 50px;
}
.ancillary-seat-selector__card_header .ancillary-seat-expander {
  float: left;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  width: 5%;
  padding-top: 0.313rem;
}
.ancillary-seat-selector__card_header .ancillary-seat-heading {
  font-size: 1.125rem;
  width: 80%;
  float: left;
  padding-top: 0.313rem;
}
.ancillary-seat-selector__card_header .ancillary-seat-heading__container {
  padding-top: 0.3125rem;
  height: 2.5rem;
  padding-left: 0.9375rem;
  float: left;
}
.ancillary-seat-selector__card_header .ancillary-seat-heading .seat-img {
  height: 1.5625rem;
  width: 1.5625rem;
}
.ancillary-seat-selector__card_header .ancillary-seat-price {
  font-size: 1.125rem;
  padding-top: 0.313rem;
  width: 15%;
  content: "";
  display: table;
  padding-top: 0.625rem;
}
.ancillary-seat-selector__card_header .ancillary-seat-price:before, .ancillary-seat-selector__card_header .ancillary-seat-price:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card_header .ancillary-seat-price:after {
  clear: both;
}
.ancillary-seat-selector__card_container {
  padding-left: 0.625rem;
  margin: 0.625rem 0.3125rem;
  background-color: #fff;
}
.ancillary-seat-selector__card_container:before, .ancillary-seat-selector__card_container:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card_container:after {
  clear: both;
}
.ancillary-seat-selector__card_container .ancillary-seat-leg-selector {
  background: #c7e5f6;
  color: #55595c;
  width: 100%;
}
.ancillary-seat-selector__card_container .ancillary-seat-leg-selector:before, .ancillary-seat-selector__card_container .ancillary-seat-leg-selector:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card_container .ancillary-seat-leg-selector:after {
  clear: both;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__header {
  color: #087dc2;
  border-bottom: 0.0625rem solid #0A9DF3;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__header:before, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector__header:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__header:after {
  clear: both;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details {
  margin-left: -15px;
  margin-right: -15px;
  width: inherit;
  margin: 0.3125rem;
  padding-top: 0.3125rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details:before, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details:after {
  clear: both;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details_current-leg {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details_current-leg:before, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details_current-leg:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__pax-details_current-leg:after {
  clear: both;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-pax,
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .seat-price,
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  margin: 0rem 0.3125rem;
  padding-left: 0rem;
  font-size: 0.875rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat {
  cursor: pointer;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat__seat-number, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat__seat-clear, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat__seat-change {
  float: left;
  height: 0.9375rem;
  margin-right: 0.3125rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat__seat-number {
  width: 1.563rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat__seat-change {
  color: #087dc2;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .current-seat__seat-clear {
  color: #ec2227;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector .seat-price {
  font-family: "Open Sans";
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details {
  border-bottom: 0.03125rem solid #959B9E;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #087dc2;
  content: "";
  display: table;
  width: 100%;
  font-size: 12px;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-code {
  width: 50%;
  text-align: center;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-code .airline-code__title, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-code .airline-code__separator, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-code .airline-code__value {
  float: left;
  padding-right: 0.313rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-code .airline-code__title {
  padding-left: 0.625rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-class {
  float: left;
  width: 50%;
  text-align: right;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-class .airline-class__title, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-class .airline-class__separator, .ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-class .airline-class__value {
  float: left;
  padding-right: 0.313rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__airline-details_airline-class .airline-class__title {
  padding-left: 0.625rem;
}
.ancillary-seat-selector__card_container .ancillary-seat-pax-selector__radio-button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 16.6666666667%;
  padding-top: 1rem;
  padding-left: 15px;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-details {
  float: left;
  width: 100%;
  padding: 0.9375rem;
  border: 0.0625rem solid;
  margin-bottom: 0.3125rem;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-details:before, .ancillary-seat-selector__card .ancillary-seat-map-selector__seat-details:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-details:after {
  clear: both;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-details_seat-info {
  float: left;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-details_seat-container {
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 100%;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info {
  background-color: #eceeef;
  padding: 0.625rem 1rem;
  margin: 0.625rem 0rem;
  width: 100%;
  content: "";
  display: table;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info:before, .ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info:after {
  content: " ";
  display: table;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info:after {
  clear: both;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat {
  width: 14%;
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  margin-top: 10px;
  float: left;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat .seat-available,
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat .seat-occupied,
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat .seat-reserved,
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat .not-available,
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat .seat-emergancy,
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat .seat-selected,
.ancillary-seat-selector__card .ancillary-seat-map-selector__seat-info_current-seat .seat-blocked {
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  background-size: cover;
  float: left;
  margin-right: 0.5rem;
  transform: scaleX(-1);
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-label {
  font-size: 0.625rem;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-row-container {
  width: 1.563rem;
  display: inline-block;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-row-container__row-number {
  float: left;
  padding: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  font-size: 1rem;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-row-container__row-number-empty {
  margin-top: 1.875rem;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-row-container__seat-container {
  float: left;
  width: 1.563rem;
  height: 1.563rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-default {
  transform: scaleX(-1);
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-available {
  color: #80CEFE;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-occupied {
  color: #838383;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-reserved {
  color: #087dc2;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .not-available {
  color: #000;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-emergancy {
  color: #9B0D11;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-selected {
  color: #FF7000;
}
.ancillary-seat-selector__card .ancillary-seat-map-selector .seat-blocked {
  color: #ec2227;
}

.ancillary-seat-map-tooltip {
  width: 200px;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-size: 0.75rem;
}
.ancillary-seat-map-tooltip__header {
  color: #087dc2;
  font-size: 0.938rem;
}
.ancillary-seat-map-tooltip__seat-number-container, .ancillary-seat-map-tooltip__price-container {
  width: 100%;
  padding: 0.313rem;
}
.ancillary-seat-map-tooltip__seat-number-container:before, .ancillary-seat-map-tooltip__seat-number-container:after, .ancillary-seat-map-tooltip__price-container:before, .ancillary-seat-map-tooltip__price-container:after {
  content: " ";
  display: table;
}
.ancillary-seat-map-tooltip__seat-number-container:after, .ancillary-seat-map-tooltip__price-container:after {
  clear: both;
}
.ancillary-seat-map-tooltip__seat-number-container_label, .ancillary-seat-map-tooltip__price-container_label {
  float: left;
  width: 60%;
}
.ancillary-seat-map-tooltip__seat-number-container_seat-number, .ancillary-seat-map-tooltip__seat-number-container_price, .ancillary-seat-map-tooltip__price-container_seat-number, .ancillary-seat-map-tooltip__price-container_price {
  float: left;
  color: #000;
  font-size: 0.75rem;
}
.ancillary-seat-map-tooltip__seat-extra-info {
  padding: 0.313rem;
}
.ancillary-seat-map-tooltip__seat-extra-info_header {
  color: #087dc2;
  font-size: 0.938rem;
}
.ancillary-seat-map-tooltip__seat-extra-info_details {
  padding: 0.313rem;
  color: #000;
}

.ancillary-leg-selector {
  background: #c7e5f6;
}
.ancillary-leg-selector__container {
  float: left;
  color: #55595c;
  font-size: 0.75rem;
  text-transform: uppercase;
  border-right: 1px solid;
  cursor: pointer;
}
.ancillary-leg-selector__container_title {
  width: 100%;
  color: #55595c;
  position: relative;
  border-width: 0.656rem;
  font-size: 12px;
  padding: 0.9375rem;
}
.ancillary-leg-selector__container_title:before, .ancillary-leg-selector__container_title:after {
  content: " ";
  display: table;
}
.ancillary-leg-selector__container_title:after {
  clear: both;
}
.ancillary-leg-selector__container_title-selected {
  background: #55595c;
  color: #fff;
}
.ancillary-leg-selector__container_overview-sector {
  float: left;
  padding-left: 5px;
}

.ancillary-pax-selector__header {
  color: #087dc2;
  border-bottom: 0.0625rem solid #0A9DF3;
}
.ancillary-pax-selector__header:before, .ancillary-pax-selector__header:after {
  content: " ";
  display: table;
}
.ancillary-pax-selector__header:after {
  clear: both;
}
.ancillary-pax-selector__pax-details {
  margin-left: -15px;
  margin-right: -15px;
  width: inherit;
  margin: 0.3125rem;
  padding-top: 0.3125rem;
}
.ancillary-pax-selector__pax-details:before, .ancillary-pax-selector__pax-details:after {
  content: " ";
  display: table;
}
.ancillary-pax-selector__pax-details:after {
  clear: both;
}
.ancillary-pax-selector__pax-details_current-leg {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.ancillary-pax-selector__pax-details_current-leg:before, .ancillary-pax-selector__pax-details_current-leg:after {
  content: " ";
  display: table;
}
.ancillary-pax-selector__pax-details_current-leg:after {
  clear: both;
}
.ancillary-pax-selector .current-pax,
.ancillary-pax-selector .ancillary-price,
.ancillary-pax-selector .current-select,
.ancillary-pax-selector .current-ancillary {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  margin: 0rem 0.3125rem;
  padding-left: 0rem;
  font-size: 0.875rem;
}
.ancillary-pax-selector .current-select {
  cursor: pointer;
}
.ancillary-pax-selector .current-select__meal-name, .ancillary-pax-selector .current-select__meal-clear {
  float: left;
  height: 0.9375rem;
  margin-right: 0.3125rem;
}
.ancillary-pax-selector .current-select-not-available {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
  margin: 0rem 0.3125rem;
  padding-left: 0rem;
  font-size: 0.875rem;
}
.ancillary-pax-selector .ancillary-select {
  display: flex;
}
.ancillary-pax-selector .ancillary-select__edit, .ancillary-pax-selector .ancillary-select__name, .ancillary-pax-selector .ancillary-select__clear {
  margin-right: 0.3125rem;
}
.ancillary-pax-selector .ancillary-select__clear, .ancillary-pax-selector .ancillary-select__edit {
  cursor: pointer;
}
.ancillary-pax-selector .ancillary-select__edit {
  color: #087dc2;
}
.ancillary-pax-selector .ancillary-price {
  font-family: "Open Sans";
}

.selected-ancillary {
  padding-top: 0.9375rem;
}
.selected-ancillary__card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  margin-bottom: 0.9375rem;
}
.selected-ancillary__card_header {
  background-color: #fff;
  color: #087dc2;
  padding-top: 0.625rem;
  padding-bottom: 0.3125rem;
  font-weight: 600;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 0.0625rem solid #959B9E;
}
.selected-ancillary__card_container {
  width: 100%;
  padding: 0.5rem;
}
.selected-ancillary__card_container:before, .selected-ancillary__card_container:after {
  content: " ";
  display: table;
}
.selected-ancillary__card_container:after {
  clear: both;
}
.selected-ancillary__card_container .selected-seat-details,
.selected-ancillary__card_container .selected-baggage-details,
.selected-ancillary__card_container .selected-meal-details,
.selected-ancillary__card_container .selected-other-ancillary-details {
  width: 100%;
  padding: 0.1rem;
}
.selected-ancillary__card_container .selected-seat-details:before, .selected-ancillary__card_container .selected-seat-details:after,
.selected-ancillary__card_container .selected-baggage-details:before,
.selected-ancillary__card_container .selected-baggage-details:after,
.selected-ancillary__card_container .selected-meal-details:before,
.selected-ancillary__card_container .selected-meal-details:after,
.selected-ancillary__card_container .selected-other-ancillary-details:before,
.selected-ancillary__card_container .selected-other-ancillary-details:after {
  content: " ";
  display: table;
}
.selected-ancillary__card_container .selected-seat-details:after,
.selected-ancillary__card_container .selected-baggage-details:after,
.selected-ancillary__card_container .selected-meal-details:after,
.selected-ancillary__card_container .selected-other-ancillary-details:after {
  clear: both;
}
.selected-ancillary__card_container .selected-seat-details__quantity, .selected-ancillary__card_container .selected-seat-details__title,
.selected-ancillary__card_container .selected-baggage-details__quantity,
.selected-ancillary__card_container .selected-baggage-details__title,
.selected-ancillary__card_container .selected-meal-details__quantity,
.selected-ancillary__card_container .selected-meal-details__title,
.selected-ancillary__card_container .selected-other-ancillary-details__quantity,
.selected-ancillary__card_container .selected-other-ancillary-details__title {
  float: left;
  width: 38%;
  padding-right: 0.5rem;
}
.selected-ancillary__card_container .selected-seat-details__title,
.selected-ancillary__card_container .selected-baggage-details__title,
.selected-ancillary__card_container .selected-meal-details__title,
.selected-ancillary__card_container .selected-other-ancillary-details__title {
  width: 40%;
}
.selected-ancillary__card_container .selected-seat-details__price,
.selected-ancillary__card_container .selected-baggage-details__price,
.selected-ancillary__card_container .selected-meal-details__price,
.selected-ancillary__card_container .selected-other-ancillary-details__price {
  padding-left: 0.975rem;
}
.selected-ancillary__card_container .selected-seat-details__price:before, .selected-ancillary__card_container .selected-seat-details__price:after,
.selected-ancillary__card_container .selected-baggage-details__price:before,
.selected-ancillary__card_container .selected-baggage-details__price:after,
.selected-ancillary__card_container .selected-meal-details__price:before,
.selected-ancillary__card_container .selected-meal-details__price:after,
.selected-ancillary__card_container .selected-other-ancillary-details__price:before,
.selected-ancillary__card_container .selected-other-ancillary-details__price:after {
  content: " ";
  display: table;
}
.selected-ancillary__card_container .selected-seat-details__price:after,
.selected-ancillary__card_container .selected-baggage-details__price:after,
.selected-ancillary__card_container .selected-meal-details__price:after,
.selected-ancillary__card_container .selected-other-ancillary-details__price:after {
  clear: both;
}
.selected-ancillary__card_container .selected-baggage-details__baggage-details, .selected-ancillary__card_container .selected-baggage-details__meal-details, .selected-ancillary__card_container .selected-baggage-details__other-ancillary-details,
.selected-ancillary__card_container .selected-meal-details__baggage-details,
.selected-ancillary__card_container .selected-meal-details__meal-details,
.selected-ancillary__card_container .selected-meal-details__other-ancillary-details,
.selected-ancillary__card_container .selected-other-ancillary-details__baggage-details,
.selected-ancillary__card_container .selected-other-ancillary-details__meal-details,
.selected-ancillary__card_container .selected-other-ancillary-details__other-ancillary-details {
  float: left;
  width: 60%;
}
.selected-ancillary__card_container .selected-baggage-details__baggage-details_baggage-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__baggage-details_meal-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__baggage-details_other-ancillary-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__meal-details_baggage-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__meal-details_meal-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__meal-details_other-ancillary-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__other-ancillary-details_baggage-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__other-ancillary-details_meal-detail .ancillary-name, .selected-ancillary__card_container .selected-baggage-details__other-ancillary-details_other-ancillary-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__baggage-details_baggage-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__baggage-details_meal-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__baggage-details_other-ancillary-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__meal-details_baggage-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__meal-details_meal-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__meal-details_other-ancillary-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__other-ancillary-details_baggage-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__other-ancillary-details_meal-detail .ancillary-name,
.selected-ancillary__card_container .selected-meal-details__other-ancillary-details_other-ancillary-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__baggage-details_baggage-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__baggage-details_meal-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__baggage-details_other-ancillary-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__meal-details_baggage-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__meal-details_meal-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__meal-details_other-ancillary-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__other-ancillary-details_baggage-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__other-ancillary-details_meal-detail .ancillary-name,
.selected-ancillary__card_container .selected-other-ancillary-details__other-ancillary-details_other-ancillary-detail .ancillary-name {
  float: left;
  width: 70%;
}
.selected-ancillary__card_container .selected-baggage-details__baggage-details_baggage-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__baggage-details_meal-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__baggage-details_other-ancillary-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__meal-details_baggage-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__meal-details_meal-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__meal-details_other-ancillary-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__other-ancillary-details_baggage-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__other-ancillary-details_meal-detail .ancillary-rate, .selected-ancillary__card_container .selected-baggage-details__other-ancillary-details_other-ancillary-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__baggage-details_baggage-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__baggage-details_meal-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__baggage-details_other-ancillary-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__meal-details_baggage-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__meal-details_meal-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__meal-details_other-ancillary-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__other-ancillary-details_baggage-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__other-ancillary-details_meal-detail .ancillary-rate,
.selected-ancillary__card_container .selected-meal-details__other-ancillary-details_other-ancillary-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__baggage-details_baggage-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__baggage-details_meal-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__baggage-details_other-ancillary-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__meal-details_baggage-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__meal-details_meal-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__meal-details_other-ancillary-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__other-ancillary-details_baggage-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__other-ancillary-details_meal-detail .ancillary-rate,
.selected-ancillary__card_container .selected-other-ancillary-details__other-ancillary-details_other-ancillary-detail .ancillary-rate {
  float: left;
  width: 30%;
  text-align: right;
}
.selected-ancillary__card_container .pax-details {
  border-bottom: 0.0625rem solid #000;
  padding: 0.625rem 0rem;
  color: #ec2227;
  width: 100%;
}
.selected-ancillary__card_container .pax-details:before, .selected-ancillary__card_container .pax-details:after {
  content: " ";
  display: table;
}
.selected-ancillary__card_container .pax-details:after {
  clear: both;
}
.selected-ancillary__card_container .pax-details__expander {
  float: left;
  margin-right: 0.625rem;
}
.selected-ancillary__card_container .pax-details__title {
  float: left;
  width: 60%;
}
.selected-ancillary__card_container .pax-details__price {
  padding-left: 1.5rem;
  font-family: "Open Sans";
  text-align: right;
}
.selected-ancillary__card_container .pax-details__price:before, .selected-ancillary__card_container .pax-details__price:after {
  content: " ";
  display: table;
}
.selected-ancillary__card_container .pax-details__price:after {
  clear: both;
}
.selected-ancillary__card_container .flight-info {
  padding-top: 0.625rem;
}
.selected-ancillary__card_container .flight-info__title, .selected-ancillary__card_container .flight-info__details {
  width: 100%;
}
.selected-ancillary__card_container .flight-info__title {
  color: #087dc2;
}
.selected-ancillary__card_container .flight-info__details {
  width: 100%;
  border-bottom: 0.0625rem solid #959B9E;
}
.selected-ancillary__card_container .flight-info__details:before, .selected-ancillary__card_container .flight-info__details:after {
  content: " ";
  display: table;
}
.selected-ancillary__card_container .flight-info__details:after {
  clear: both;
}
.selected-ancillary__card_container .flight-info__details_name {
  float: left;
  width: 35%;
}
.selected-ancillary__card_container .flight-info__details_detail {
  float: right;
}
.selected-ancillary__card_container .selected-seat-details__quantity {
  width: 30% !important;
}
.selected-ancillary__card_container .selected-seat-details__price {
  float: left;
  width: 30%;
  text-align: right;
}
.selected-ancillary__card_foooter .total-price {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #087dc2;
  width: 100%;
  border-bottom: 0.03125rem solid #959B9E;
  border-top: 0.03125rem solid #959B9E;
}
.selected-ancillary__card_foooter .total-price:before, .selected-ancillary__card_foooter .total-price:after {
  content: " ";
  display: table;
}
.selected-ancillary__card_foooter .total-price:after {
  clear: both;
}
.selected-ancillary__card_foooter .total-price__title {
  float: left;
  width: 45%;
  text-align: left;
}
.selected-ancillary__card_foooter .total-price__price {
  text-align: right;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
}
.selected-ancillary__card_foooter .total-price__price_currency-code {
  float: right;
  margin-right: 0.1rem;
}
.selected-ancillary__card_foooter .total-price__price_currency-rate {
  float: right;
  font-family: "Open Sans";
}

.other-ancillary-selector {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
.other-ancillary-selector_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.other-ancillary-selector_main:before, .other-ancillary-selector_main:after {
  content: " ";
  display: table;
}
.other-ancillary-selector_main:after {
  clear: both;
}
.other-ancillary-selector_main .content {
  padding-left: 0;
}
.other-ancillary-selector_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .other-ancillary-selector_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .other-ancillary-selector_wrapper {
    max-width: 1400px;
  }
}
.other-ancillary-selector_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.other-ancillary-selector_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.other-ancillary-selector_row:before, .other-ancillary-selector_row:after {
  content: " ";
  display: table;
}
.other-ancillary-selector_row:after {
  clear: both;
}
.other-ancillary-selector_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.other-ancillary-selector_card:before, .other-ancillary-selector_card:after {
  content: " ";
  display: table;
}
.other-ancillary-selector_card:after {
  clear: both;
}
.other-ancillary-selector_card_header {
  padding: 0.375rem 0.9375rem;
}
.other-ancillary-selector_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.other-ancillary-selector_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.other-ancillary-selector_header ul:before, .other-ancillary-selector_header ul:after {
  content: " ";
  display: table;
}
.other-ancillary-selector_header ul:after {
  clear: both;
}
.other-ancillary-selector_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.other-ancillary-selector_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.other-ancillary-selector_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.other-ancillary-selector_card_content {
  margin-bottom: 0.9375rem;
}
.other-ancillary-selector:before, .other-ancillary-selector:after {
  content: " ";
  display: table;
}
.other-ancillary-selector:after {
  clear: both;
}
.other-ancillary-selector .content {
  padding-left: 0 !important;
  padding-right: 0;
  width: 100%;
}
.other-ancillary-selector__card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-top-left-radius: 0.3125rem;
  border-top-right-radius: 0.3125rem;
}
.other-ancillary-selector__card_header {
  text-transform: uppercase;
  color: #087dc2;
  background: #fff;
  padding-left: 0.3125rem;
  height: 3.125rem;
}
.other-ancillary-selector__card_header:before, .other-ancillary-selector__card_header:after {
  content: " ";
  display: table;
}
.other-ancillary-selector__card_header:after {
  clear: both;
}
.other-ancillary-selector__card .other-ancillary-expander {
  float: left;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  width: 2.813rem;
  padding-top: 0.5rem;
}
.other-ancillary-selector__card .other-ancillary-heading {
  font-size: 1.125rem;
  float: left;
  padding-top: 0.375rem;
}
.other-ancillary-selector__card .other-ancillary-heading__container {
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding-left: 0.9375rem;
}
.other-ancillary-selector__card .other-ancillary-heading__container_header-text {
  padding-left: 0.3125rem;
}
.other-ancillary-selector__card .other-ancillary-price {
  font-size: 1.125rem;
  float: right;
  padding-top: 0.75rem;
  padding-right: 0.75rem;
}
.other-ancillary-selector__card .other-ancillary-price__currency-code {
  font-size: 1.25rem;
  float: left;
  margin-right: 0.25rem;
}
.other-ancillary-selector__card .other-ancillary-price__currency-rate {
  float: right;
  font-family: "Open Sans";
  font-size: 1.25rem;
}
.other-ancillary-selector__card_container {
  padding-left: 0.625rem;
  margin: 0.625rem 0.3125rem;
}
.other-ancillary-selector__card_container:before, .other-ancillary-selector__card_container:after {
  content: " ";
  display: table;
}
.other-ancillary-selector__card_container:after {
  clear: both;
}
.other-ancillary-selector__card_container .other-ancillary-leg-selector {
  background: #c7e5f6;
  color: #55595c;
}
.other-ancillary-selector__card_container .other-ancillary-leg-selector:before, .other-ancillary-selector__card_container .other-ancillary-leg-selector:after {
  content: " ";
  display: table;
}
.other-ancillary-selector__card_container .other-ancillary-leg-selector:after {
  clear: both;
}
.other-ancillary-selector__card_container .other-ancillary-type-selector {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 45%;
  margin-top: 0.9375rem;
}
.other-ancillary-selector__card_container .other-ancillary-type-selector__title {
  margin-bottom: 0.25rem;
  color: #087dc2;
  font-size: 0.875rem;
  float: left;
  padding-right: 0.625rem;
  margin-top: 0.3125rem;
}
.other-ancillary-selector__card_container .other-ancillary-type-selector select {
  width: 50%;
  padding: 0.5rem 1rem;
  background: none;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: normal;
  border: 1px solid #d0d0d0;
  max-height: 2.125rem;
}
.other-ancillary-selector__card_container .card-content {
  padding-bottom: 0.625rem;
}

.ancillary-baggage-selector {
  padding-top: 0.9375rem;
}
.ancillary-baggage-selector_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.ancillary-baggage-selector_main:before, .ancillary-baggage-selector_main:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector_main:after {
  clear: both;
}
.ancillary-baggage-selector_main .content {
  padding-left: 0;
}
.ancillary-baggage-selector_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .ancillary-baggage-selector_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .ancillary-baggage-selector_wrapper {
    max-width: 1400px;
  }
}
.ancillary-baggage-selector_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.ancillary-baggage-selector_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.ancillary-baggage-selector_row:before, .ancillary-baggage-selector_row:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector_row:after {
  clear: both;
}
.ancillary-baggage-selector_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.ancillary-baggage-selector_card:before, .ancillary-baggage-selector_card:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector_card:after {
  clear: both;
}
.ancillary-baggage-selector_card_header {
  padding: 0.375rem 0.9375rem;
}
.ancillary-baggage-selector_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.ancillary-baggage-selector_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.ancillary-baggage-selector_header ul:before, .ancillary-baggage-selector_header ul:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector_header ul:after {
  clear: both;
}
.ancillary-baggage-selector_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.ancillary-baggage-selector_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.ancillary-baggage-selector_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.ancillary-baggage-selector_card_content {
  margin-bottom: 0.9375rem;
}
.ancillary-baggage-selector:before, .ancillary-baggage-selector:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector:after {
  clear: both;
}
.ancillary-baggage-selector .content {
  padding-left: 0px !important;
  padding-right: 0px;
  width: 100%;
}
.ancillary-baggage-selector__card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-top-left-radius: 0.313rem;
  border-top-right-radius: 0.313rem;
}
.ancillary-baggage-selector__card_header {
  text-transform: uppercase;
  color: #087dc2;
  background: #fff;
  padding-left: 0.313rem;
  height: 3.125rem;
}
.ancillary-baggage-selector__card_header:before, .ancillary-baggage-selector__card_header:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector__card_header:after {
  clear: both;
}
.ancillary-baggage-selector__card .ancillary-baggage-expander {
  float: left;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  width: 5%;
  padding-top: 0.5rem;
}
.ancillary-baggage-selector__card .ancillary-baggage-heading {
  font-size: 1.125rem;
  float: left;
  padding-top: 0.375rem;
}
.ancillary-baggage-selector__card .ancillary-baggage-heading__container {
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding-left: 0.938rem;
  width: 22%;
}
.ancillary-baggage-selector__card .ancillary-baggage-heading__container_header-text {
  padding-left: 0.313rem;
}
.ancillary-baggage-selector__card .ancillary-baggage-price {
  font-size: 1.125rem;
  float: right;
  padding-top: 0.75rem;
  padding-right: 0.75rem;
}
.ancillary-baggage-selector__card .ancillary-baggage-price__currency-code {
  font-size: 1.25rem;
  float: left;
  margin-right: 0.25rem;
}
.ancillary-baggage-selector__card .ancillary-baggage-price__currency-rate {
  float: right;
  font-family: "Open Sans";
  font-size: 1.25rem;
}
.ancillary-baggage-selector__card_container {
  padding-left: 0.625rem;
  margin: 0.625rem 0.313rem;
}
.ancillary-baggage-selector__card_container:before, .ancillary-baggage-selector__card_container:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector__card_container:after {
  clear: both;
}
.ancillary-baggage-selector__card_container .ancillary-baggage-leg-selector {
  background: #c7e5f6;
  color: #55595c;
}
.ancillary-baggage-selector__card_container .ancillary-baggage-leg-selector:before, .ancillary-baggage-selector__card_container .ancillary-baggage-leg-selector:after {
  content: " ";
  display: table;
}
.ancillary-baggage-selector__card_container .ancillary-baggage-leg-selector:after {
  clear: both;
}
.ancillary-baggage-selector__card_container .card-content {
  padding-bottom: 0.625rem;
}

.ancillary-meal-selector {
  padding-top: 0.9375rem;
}
.ancillary-meal-selector_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.ancillary-meal-selector_main:before, .ancillary-meal-selector_main:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector_main:after {
  clear: both;
}
.ancillary-meal-selector_main .content {
  padding-left: 0;
}
.ancillary-meal-selector_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .ancillary-meal-selector_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .ancillary-meal-selector_wrapper {
    max-width: 1400px;
  }
}
.ancillary-meal-selector_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.ancillary-meal-selector_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.ancillary-meal-selector_row:before, .ancillary-meal-selector_row:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector_row:after {
  clear: both;
}
.ancillary-meal-selector_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.ancillary-meal-selector_card:before, .ancillary-meal-selector_card:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector_card:after {
  clear: both;
}
.ancillary-meal-selector_card_header {
  padding: 0.375rem 0.9375rem;
}
.ancillary-meal-selector_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.ancillary-meal-selector_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.ancillary-meal-selector_header ul:before, .ancillary-meal-selector_header ul:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector_header ul:after {
  clear: both;
}
.ancillary-meal-selector_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.ancillary-meal-selector_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.ancillary-meal-selector_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.ancillary-meal-selector_card_content {
  margin-bottom: 0.9375rem;
}
.ancillary-meal-selector:before, .ancillary-meal-selector:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector:after {
  clear: both;
}
.ancillary-meal-selector .content {
  padding-left: 0 !important;
  padding-right: 0;
  width: 100%;
}
.ancillary-meal-selector__card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-top-left-radius: 0.313rem;
  border-top-right-radius: 0.313rem;
}
.ancillary-meal-selector__card_header {
  text-transform: uppercase;
  color: #087dc2;
  background: #fff;
  padding-left: 0.313rem;
  height: 50px;
}
.ancillary-meal-selector__card_header:before, .ancillary-meal-selector__card_header:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector__card_header:after {
  clear: both;
}
.ancillary-meal-selector__card .ancillary-meal-expander {
  float: left;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  width: 5%;
  padding-top: 0.5rem;
}
.ancillary-meal-selector__card .ancillary-meal-heading {
  font-size: 1.125rem;
  float: left;
  padding-top: 0.375rem;
}
.ancillary-meal-selector__card .ancillary-meal-heading__container {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 0.938rem;
  width: 22%;
}
.ancillary-meal-selector__card .ancillary-meal-heading__container_header-text {
  padding-left: 0.313rem;
}
.ancillary-meal-selector__card .ancillary-meal-price {
  font-size: 1.125rem;
  float: right;
  padding-top: 0.75rem;
  padding-right: 0.75rem;
}
.ancillary-meal-selector__card .ancillary-meal-price__currency-code {
  font-size: 1.25rem;
  float: left;
  margin-right: 0.25rem;
}
.ancillary-meal-selector__card .ancillary-meal-price__currency-rate {
  float: right;
  font-family: "Open Sans";
  font-size: 1.25rem;
}
.ancillary-meal-selector__card_container {
  padding-left: 0.625rem;
  margin: 0.625rem 0.313rem;
}
.ancillary-meal-selector__card_container:before, .ancillary-meal-selector__card_container:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector__card_container:after {
  clear: both;
}
.ancillary-meal-selector__card_container .ancillary-meal-leg-selector {
  background: #c7e5f6;
  color: #55595c;
}
.ancillary-meal-selector__card_container .ancillary-meal-leg-selector:before, .ancillary-meal-selector__card_container .ancillary-meal-leg-selector:after {
  content: " ";
  display: table;
}
.ancillary-meal-selector__card_container .ancillary-meal-leg-selector:after {
  clear: both;
}
.ancillary-meal-selector__card_container .card-content {
  padding-bottom: 0.625rem;
}

.air__dubz_main {
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-top: 1.875rem;
}
.air__dubz_main:before, .air__dubz_main:after {
  content: " ";
  display: table;
}
.air__dubz_main:after {
  clear: both;
}
.air__dubz_main .content {
  padding-left: 0;
}
.air__dubz_wrapper {
  max-width: 960px;
  margin: 0 auto;
}
@media (min-width: 1280px) {
  .air__dubz_wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .air__dubz_wrapper {
    max-width: 1400px;
  }
}
.air__dubz_wrapper .single-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 100%;
}
.air__dubz_row {
  margin-left: -15px;
  margin-right: -15px;
  text-transform: uppercase;
}
.air__dubz_row:before, .air__dubz_row:after {
  content: " ";
  display: table;
}
.air__dubz_row:after {
  clear: both;
}
.air__dubz_card {
  padding: 0 0.9375rem;
  margin-bottom: 0.9375rem;
  background-color: #fff;
}
.air__dubz_card:before, .air__dubz_card:after {
  content: " ";
  display: table;
}
.air__dubz_card:after {
  clear: both;
}
.air__dubz_card_header {
  padding: 0.375rem 0.9375rem;
}
.air__dubz_header {
  background-color: #f0f5f8;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #087dc2;
  font-weight: 700;
}
.air__dubz_header ul {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 0.3125rem;
  padding-top: 0.3125rem;
  text-transform: uppercase;
}
.air__dubz_header ul:before, .air__dubz_header ul:after {
  content: " ";
  display: table;
}
.air__dubz_header ul:after {
  clear: both;
}
.air__dubz_header ul > li {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
  float: left;
  text-align: center;
}
.air__dubz_header ul > li:nth-child(2) {
  width: 30%;
  text-align: left;
}
.air__dubz_header ul > li:last-child {
  width: 30%;
  text-align: right;
}
.air__dubz_card_content {
  margin-bottom: 0.9375rem;
}
.air__dubz_footer {
  margin-left: -15px;
  margin-right: -15px;
}
.air__dubz_footer:before, .air__dubz_footer:after {
  content: " ";
  display: table;
}
.air__dubz_footer:after {
  clear: both;
}
.air__dubz_flight {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.air__dubz_from, .air__dubz_to {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 40%;
}
.air__dubz_duration {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 20%;
}
.air__dubz_wrapper {
  padding-top: 0.9375rem;
}
.air__dubz_wrapper:before, .air__dubz_wrapper:after {
  content: " ";
  display: table;
}
.air__dubz_wrapper:after {
  clear: both;
}
.air__dubz_wrapper .content {
  padding-left: 0.9375rem;
  padding-right: 0;
}
.air__dubz_wrapper .dubz-content {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
}
.air__dubz_wrapper .dubz-content__dubz-details_wrapper {
  width: 100%;
}
.air__dubz_wrapper .dubz-content__price_wrapper {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 25%;
}
.air__dubz_wrapper .dubz-content__price_wrapper .sidebar {
  width: 100%;
}
.air__dubz_wrapper .dubz-content__price_wrapper .sidebar .book-card__table {
  width: 100%;
}

.dubz-input .content {
  padding-left: 0.9375rem;
  padding-right: 0;
  width: 100%;
}
.dubz-input__card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.dubz-input__card_header {
  text-transform: uppercase;
  color: #087dc2;
  padding-left: 0;
}
.dubz-input__card .dubz-heading {
  font-size: 1.125rem;
  background-color: #c7e5f6;
}
.dubz-input__card .dubz-heading__container {
  padding-top: 0.3125rem;
  height: 2.5rem;
  padding-left: 0.9375rem;
  width: 24%;
  background-color: #fff;
}

.dubz-details {
  content: "";
  display: table;
  width: 100%;
}
.dubz-details__img {
  width: 25%;
  float: left;
  padding: 0.625rem 0.938rem;
}
.dubz-details__img_dubz-img {
  width: 95%;
  height: 7rem;
}
.dubz-details__note {
  float: left;
  width: 55%;
  text-align: left;
  padding-top: 1rem;
}
.dubz-details__note_additional-info {
  color: #087dc2;
}
.dubz-details__price {
  float: right;
  width: 20%;
  text-align: center;
  padding-top: 1rem;
}
.dubz-details__price_header {
  color: #087dc2;
}

.add-dubz-details {
  content: "";
  display: table;
  padding-bottom: 0.625rem;
  width: 100%;
  padding-top: 1rem;
}
.add-dubz-details__dubz-container {
  padding-left: 1rem;
}
.add-dubz-details__dubz-container_row {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  float: left;
  width: 33%;
}
.add-dubz-details input[type=text] {
  width: 70%;
  padding: 0.1875rem;
  background: none;
  vertical-align: middle;
  line-height: normal;
  background-color: #fff;
  border: 1px solid;
  border-color: #d0d0d0;
  max-height: 1.25rem;
}
.add-dubz-details select {
  width: 70%;
  padding: 0 0.1875rem;
  background: none;
  vertical-align: middle;
  line-height: normal;
  max-height: 1.25rem;
}

.row_input-label {
  color: #087dc2;
  padding-bottom: 0.5rem;
}
.row_input-field {
  width: 80%;
  border: 1px solid #d0d0d0;
}

.input-field__country-code {
  float: left;
  width: 17%;
}
.input-field__mobile_num {
  float: left;
  width: 77%;
}

.add-dubz-details-checkBox {
  content: "";
  display: table;
  padding: 1rem;
}
.add-dubz-details-checkBox__static-note {
  padding: 0.625rem;
}
.add-dubz-details-checkBox__terms {
  color: #087dc2;
}

.add-dubz-details-button {
  padding-left: 1.875rem;
  margin-bottom: 1.875rem;
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 75%;
  padding: 1rem;
  background-color: #fff;
  border-top: 2px solid #087dc2;
  width: 100%;
}
.add-dubz-details-button__add-dubz-button {
  float: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  min-height: 1px;
  width: 50%;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-left: 1.875rem;
  background-color: #ec2227;
  border: none;
  line-height: inherit;
  border-radius: 0;
  background-image: none;
  width: 25%;
  color: #fff;
  font-weight: 700;
  float: right;
}
.add-dubz-details-button__add-dubz-button:hover {
  background-color: #9b0d11;
}
.add-dubz-details-button__add-dubz-button:disabled {
  background-color: #f48083;
  cursor: not-allowed;
}
.add-dubz-details-button__add-dubz-button:disabled:hover {
  background-color: #f48083;
}

.dubz-fare-details {
  width: 100%;
  padding-left: 0.938rem;
  float: left;
}
.dubz-fare-details__dubz-fare-row {
  float: left;
  width: 14.28%;
}
.dubz-fare-details__dubz-fare-row_fare-label {
  padding-top: 0.938rem;
  color: #087dc2;
}
.dubz-fare-details__dubz-fare-row_fare {
  padding-top: 0.625rem;
}

.dubz-header {
  height: 1.875rem;
  padding-left: 0.938rem;
  margin-right: 0.234375rem;
  font-size: 0.9rem;
  background-color: #eceeef;
  margin-top: 0.313rem;
  padding-top: 0.313rem;
  width: 100%;
}