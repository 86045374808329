.air__search_results {

   @include create-recommended-product-icon();

  .search__result {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
    &_card {
      box-shadow: $row-shadow-color;
      @include clearfix;
    }
    &_row {
    	position: relative;
    }
    &_tax-header{
	    @include clearfix;
		margin-top: -22px;
		margin-left: 25%;
		margin-right: 15px;
		padding: 0.25rem;
	    color: $dark-green;
	    background-color: $saffron;
	    box-shadow: $row-shadow-color;
	    margin-bottom: 1rem;
	    	.tax-message{
	    		font-size: 0.9rem;
	    		padding-left: 0.25rem;
	    	}
    }
    
	&_header{
	text-transform:uppercase;
	color:$brand-color-2;
	border-bottom: 1px solid darken($brand-color-4,20);
	padding-top: $base-padding/5;
	padding-bottom: $base-padding/5;
	padding-left: $base-padding/3;
    padding-right: $base-padding/3;		
    margin-bottom: $base-margin/5;
    margin-left: -$base-margin/2;
    margin-right: -15px;
    &-travel-dates{
    	float:right;
    }
	}
    &_flight,
    &_price {
      @include make-one-fifth;
    }
    &_flight {
    }
    &_price {
 		padding-left:10px;
        padding-right:10px;
		.reissue-result {
			text-align: center;
    		display: flex;
    		text-transform: none;
			align-content: center;
    		align-items: center;
    		justify-content: center;
			position: absolute;
    		top: 0;
    		bottom: 0;
    		padding-right: $base-padding/3;
			color: $brand-color-2;
			&__error-message {
				align-items: end;
				padding-bottom: $base-padding/3;
				color: $brand-color-1;
			}
		}
    }
    &_trip {
    	@include make-three-fifth;
    	padding-left: 0;
    	padding-right: 0;
    	border-right: 1px solid;
      border-left: 1px solid;
      border-color: $row-flight-border-color;
		height: 100%;
      .trip {
      	float: left;
    	width: 100%;
      }
    }
    &_from,
    &_to,
    &_duration {
    	@include make-one-third;
    }
  }
  .result {
    &__fname {
      text-transform: capitalize;
    }
    &__plane{
    	color: $water-blue;
    }
    &__flight,
    &__from,
    &__to,
    &__duration,
    &__price {
      padding-top: $base-padding/2;

      // padding-bottom: $base-padding/2;
      min-height: 100px;
     
    }
    &__flight,
    &__duration {
      text-align: center;
    }
    &__flight {
   	 @include clearfix();
      width:100%;
      &_image-container{
         max-width:120px;
     	margin:0 auto;
     	
     	.image-container__image {
 	    	max-width: 28px;
 	    	max-height: 30px;
 	    	display:inline-block;
 	  	  }
     }
    }
    &__from {
      text-align: left;
    }
    &__to {
      text-align: right;
    }
    &__from,
    &__to {
      &_time,
      &_date {
        font-family: $font-family-number;

        // font-weight: 600;
      }
      &_date {

        // font-weight: bold;
      }
      &_time {
        font-size: $font-size-number;
        color: $row-time-color;
        font-weight: 400;
      }
    }
    &__duration {
      &_time {
        font-size: $font-size-number;
        font-family: $font-family-number;
        font-weight: 400;
        color: $row-duration-color;
        text-transform: lowercase;
      }
      &_type {
        position: relative;
        &:before,
        &:after {
          width: 80px;
          content: "";
          height: 1px;
          background-color: lighten($brand-color-5, 30);
          position: absolute;
          top: 50%;
        }
        &:before {
          margin-right: -20px;
          right: 100%;
        }
        &:after {
          margin-left: -20px;
          left: 100%;
        }
      }
      &_legs {
        font-size: $font-size-h6;
      }
    }
    
    &__flexi-info-icon {
    	font-size: 1rem;
    	color: $brand-color-2;
    	padding-left: 0.5rem;
	}
   	 
    &__price {
      &_currency {

      }
      &_amount,&_amount-with-adhoc {
        font-size: $font-size-h3;
        font-family: $font-family-number;
		color: $brand-color-1;
        // font-weight: 400;
      }
      &_amount-with-adhoc{
       	color : $brand-color-14;
	
      }
      &_adhoc-amount{
   	  	display: inline-block;
   	  	width: 100%;
    	text-align: center;
      }
	  	&_offer-price{
			position: absolute;
			bottom: 0;
			.offer-price{
				&__title{
					color: $brand-color-2;
					text-transform: capitalize;
				}
				&__amount{
					color: $brand-color-1;
				}
			}
		}
    }
    &__flight_details_button,
    &__extra_details_text {
      text-transform: uppercase;
      font-size: $font-size-h6;
      font-weight: 600;
      padding: .3125rem 0;
      text-align: right;
	  padding-bottom: 0.125rem;
    }
    &__extra_details_lodgecard {
      width:6%;
      font-size: $font-size-h3;
    }
    &__flight_details {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      &_button {
        width: 100%;
        background-color: $row-flight-details-button-bgcolor;
        padding-top: $base-padding/6;
        padding-bottom: $base-padding/6;
        display: block;
        text-align: center;
        color: $brand-color-2;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          color: $row-flight-details-button-state;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__extra_details {
      @include make-three-fifth;
      background-color: $row-footer-bgcolor;
      border-right: 1px solid;
      border-left: 1px solid;
      border-color: $row-flight-details-border-color;
      &_text {
        float: right;
        color:$brand-color-2;
    	position: relative;
    	display: inline-flex;
    	.fare-type {
    		white-space: nowrap;
    		text-overflow: ellipsis;
    		max-width: 9rem;
    		overflow: hidden;
    		color:$brand-color-2;
    	}

      .supplier-type{
        padding-left: 1rem;
      }
    	
      }
      &_llf-info{
        font-size: $font-size-h6;
        float: right;
    	color: $brand-color-2;
    	.llf-info{
        	&__button{
        		background-color: $row-footer-bgcolor;
        	}
        }
      }
      &_highlight-duplicate{
        float: left;
        font-size:1.2rem;
        color:$brand-color-1;
        margin-right:0.5rem;
      	.highlight-duplicate{
        	&__button{
        		background-color: $row-footer-bgcolor;
        	}
        }
      }
    }
    &__book, &__select {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      &_button {
        @include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
        @include make-button($light, $row-book-button-bgcolor, $light, darken($row-book-button-state,20));
        width: 100%;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    &__select {
      float:right;
      height:0px;
      &_button {
        background-color: $brand-color-2;
        &:hover,
        &:focus,
        &:active {
          background-color: $brand-color-2;
        }

        &-active {
          color: $brand-color-2;
          background-color: $brand-color-3;
          &:hover,
          &:focus,
          &:active {
            background-color: $brand-color-3;
          }
        }
      }
    }
  }
  .select-button__triangle {
    position: relative;
    margin-left: 5px;
    top: 10px;
    @include make-triangle(bottom, 7px, $light);
  }
  .select-button__triangle-active {
    position: relative;
    margin-left: 5px;
    top: 10px;
    @include make-triangle(top, 7px, $light);
  }
}

.air-result-price-status{
	position: absolute;
  	width: 18%;
  	bottom: 0px;
  	text-align: center;
	&__available,
  	&__unavailable,
	&__price-changed{
		margin: 0 auto;
	    max-width: 150px;
	    color: $orange;
	}
	&__available{
	    color: $green;
	}
	&__unavailable{
	    color: $brand-color-9;
	}
}

.popover-width{
	min-width:150px;
	color:$text-color;
}

.pnr-search-results {
	&__fare-result-header{
			padding-top : 0.5rem;    	
    	}
}

.air-port-message-info-icons {
  font-size: 1rem;
  color: #ec2227;
  padding-left: 0.5rem;
}

.air-port-message-modal {
  &__mesagge {
    color: #fff;
    background-color: #087dc2;
    @include no-border;
    font-size: $font-size-h5;
    font-family: $font-family-base;
    padding: 7px;
  }
  &__text {
    font-size: $font-size-h5;
    font-family: $font-family-base;
    line-height: 3;
    color: #000000d4;
  }
}
.modal-open {
  .air-port-message-modal.modal {
    overflow-x: hidden;
    overflow-y: hidden;
    width: auto;
    height: auto;
  }
}
